import '../css/Admin.css'
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

export default function Admin(){
    return (
        <div id="admin-table" style={{ overflowX: 'auto' }}>
            <Table bordered>
                <thead>
                    <tr>
                        <th className='admin-th'>Admin settings</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key='link-one'>
                        <td>
                            <Link to='/user/all' style={{ color: 'blue', fontSize: '20px' }}>
                                All users
                            </Link>
                        </td>
                    </tr>
                    <tr key='link-two'>
                        <td>
                            <Link to='/product/new' style={{ color: 'blue', fontSize: '20px' }}>
                                Add new product
                            </Link>
                        </td>
                    </tr>
                    <tr key='link-three'>
                        <td>
                            <Link to='/order/all' style={{ color: 'blue', fontSize: '20px' }}>
                               All orders
                            </Link>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}