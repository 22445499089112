import { useEffect, useState } from "react";
import SimpleAlert from '../components/SimpleAlert';

export default function SessionAlert(){
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Check if there is a session message in session storage
        const sessionMessage = sessionStorage.getItem('sessionMessage');
        if (sessionMessage) {
            // Set the session message
            setMessage(sessionMessage);
        }
    }, []); // Run once on mount

    return (
        <SimpleAlert key="alert" message={message} type='success' display={message ? 'block' : 'none'} />
    );
}