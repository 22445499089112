import * as React from 'react';
import { useNavigate  } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import SimpleAlert from '../components/SimpleAlert';
import ReactGA from 'react-ga4';
import useCsrfToken from '../customHooks/useCsrfToken';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit">
        Pharmacy
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Shipping address', 'Payment details', 'Review your order'];

export default function CheckoutPage() {
  const [formData, setFormData] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [outOfStockMsg, setOutOfStockMsg] = React.useState('');
  // Address page
  const [addressResponse, setAddressResponse] = React.useState();
  // Payment page
  const [cardResponse, setCardResponse] = React.useState();
  // Review page
  const [cartData, setCartData] = React.useState();
  const csrfToken = useCsrfToken();

  const navigate = useNavigate();

  React.useEffect(() => {
    Promise.all([fetchFavAddress(), fetchCardInfo(), fetchCartProducts()]) // race condition; wait for all async to be resolved to initialize formData
      .then(([favAddressData, cardInfoData]) => {
        initializeFormData(favAddressData, cardInfoData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleFormChange = (newFormData) => {
    setFormData(newFormData);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return addressResponse && <AddressForm formData={formData} updateFormData={handleFormChange}/>;
      case 1:
        return <PaymentForm formData={formData} updateFormData={handleFormChange}/>;
      case 2:
        return cartData && <Review cartData={cartData} formData={formData}/>;
      default:
        throw new Error('Unknown step');
    }
  }
  
  const fetchFavAddress = async () => {
    try {
      const response = await fetch('https://pharmacy.hadikaraki.net/api/user/address-form');
      const results = await response.json();
      setAddressResponse(response)
      return results;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchCardInfo = async () => {
    try {
      const response = await fetch('https://pharmacy.hadikaraki.net/api/user/payment-form');
      const results = await response.json();
      setCardResponse(response)
      return results;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchCartProducts = async () => {
    try {
      const response = await fetch('https://pharmacy.hadikaraki.net/api/user/cart');
      if (response.ok) {
        const data = await response.json();
        setCartData(data);
        let cartData = data;
        ReactGA.event('begin_checkout', {
          currency: 'USD',
          value: cartData.totalPrice,
          items: cartData.cart.map(item => ({
            item_id: item.product._id,
            item_name: item.product.english_name,
            affiliation: 'Online Store',
            price: item.product.price,
            quantity: item.quantity,
            item_category: item.product.category
          }))
        });
      } else {
        console.error('Failed to fetch products');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const initializeFormData = (favAddressData, cardInfoData) => {
    setFormData({
      first_name: favAddressData.user?.first_name || '',
      last_name: favAddressData.user?.last_name || '',
      street: favAddressData.fav_address?.street || '',
      city: favAddressData.fav_address?.city || '',
      state: favAddressData.fav_address?.state || '',
      building: favAddressData.fav_address?.building || '',
      floor: favAddressData.fav_address?.floor || '',
      phone_nb: favAddressData.user?.phone_nb || '',
      extra_info: favAddressData.fav_address?.extra_info || '',
      decryptedCardName: cardInfoData?.decryptedCardName || '',
      decryptedCardNumber: cardInfoData?.decryptedCardNumber || '',
      decryptedCVV: cardInfoData?.decryptedCVV || '',
      decryptedExpDate: cardInfoData?.decryptedExpDate || '',
      saveAddress: 'no'
    });
  };

  const handlePlaceOrder = async() => {
    try {
      var data;
      const saveAddress = formData.saveAddress;
      if (saveAddress === 'yes') {
        data = 
        { 
          address: {
            street: formData.street,
            city: formData.city,
            state: formData.state,
            building: formData.building,
            floor: formData.floor,
            phone_nb: formData.phone_nb,
            extra_info: formData.extra_info
          }
        }
      }
      const response = await fetch("https://pharmacy.hadikaraki.net/api/user/checkout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'CSRF-Token': csrfToken
          },
          body: JSON.stringify({saveAddress, data})
      });
      const result = await response.json();
      if (response.ok) {
          const items = cartData.cart.map(item => ({
            item_id: item.product._id,
            item_name: item.product.english_name,
            affiliation: 'Online Store',
            price: item.product.price,
            quantity: item.quantity
          }));
    
          // Send the purchase event to Google Analytics
          ReactGA.event('purchase', {
            transaction_id: 'T_123d45', // Assuming the API response includes an order ID
            affiliation: 'Online Store',
            value: cartData.totalPrice,
            currency: 'USD',
            items: items
          });

          sessionStorage.setItem('sessionMessage', 'Order placed');
          navigate("/user/order-history");
          window.location.reload();
      } else {
          setOutOfStockMsg(result.message);
      }
    } catch (error) {
        console.error("Error:", error);
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, borderRadius: '20px' }}>
          <SimpleAlert key="message-alert" message={outOfStockMsg} type={'error'} display={outOfStockMsg !== '' ? 'block' : 'none'}/>
          <Typography component="h1" variant="h4" align="center" style={{color: 'green'}}>
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order number is #2001539. We have emailed your order
                confirmation, and will send you an update when your order has
                shipped.
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} variant="contained" sx={{ mt: 3, ml: 1, backgroundColor: 'green' }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={activeStep === steps.length - 1 ? handlePlaceOrder : handleNext}
                  sx={{ mt: 3, ml: 1, backgroundColor: 'green' }}
                >
                  {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
}