import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

export default function Review({cartData, formData}) {
  const products = cartData.cart;
  const totalPrice = cartData.totalPrice;
  // const addresses = ['1 MUI Drive', 'Reactville', 'Anytown', '99999', 'USA'];
  const addresses = [formData.street, formData.city, formData.state, formData.building, formData.extra_info];
  const payments = [
    { name: 'Card type', detail: 'Visa' },
    { name: 'Card holder', detail: formData && formData.decryptedCardName },
    { name: 'Card number', detail: formData && formData.decryptedCardNumber },
    { name: 'Expiry date', detail: formData && formData.decryptedExpDate },
  ];
  
  return (
    <React.Fragment>
        <Typography variant="h6" gutterBottom style={{color: 'green'}}>
          Order summary
        </Typography>
        <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.product._id} sx={{ py: 1, px: 0 }}>
            <img alt='product' style={{marginRight: '15px'}} height={50} width={50} src={product.product.images[0].url}></img>
            <ListItemText primary={product.product.english_name} secondary={product.product.english_description} />
            $<Typography variant="body2">{product.product.price * product.quantity}</Typography>
          </ListItem>
        ))}
        <hr></hr>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            ${totalPrice}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2, color: 'green' }}>
            Shipping
          </Typography>
          <Typography gutterBottom>{formData.first_name} {formData.last_name}</Typography>
          <Typography gutterBottom>{addresses.join(', ')}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2, color: 'green' }}>
            Payment details
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}