import '../css/EditOrder.css'
import * as React from 'react';
import { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import SimpleAlert from '../components/SimpleAlert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import { useParams } from 'react-router-dom';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Table from 'react-bootstrap/Table';
import useCsrfToken from '../customHooks/useCsrfToken';

const defaultTheme = createTheme();

export default function EditOrder() {
    const { orderID } = useParams();
    const [formData, setFormData] = useState(null);
    const [loader, setLoader] = useState(false);
    const [successAlert, setSuccessAlert] = useState('none');
    const [errorAlert, setErrorAlert] = useState('none');
    const csrfToken = useCsrfToken();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };

    useEffect(() => {
        fetchOrder();
    }, []);
    
    const fetchOrder = async () => {
        try {
          const response = await fetch(`https://pharmacy.hadikaraki.net/api/order/edit/${orderID}`);
          if (response.ok) {
            const results = await response.json();
            setFormData(results)
          } else {
            console.error('Failed to fetch user');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

      const handleSubmit = async (event) => {
          event.preventDefault();
          setLoader(true);
          const { status, number } = formData;
          try {
              const response = await fetch(`https://pharmacy.hadikaraki.net/api/order/edit/${orderID}`, {
                  method: "PUT",
                  headers: {
                      "Content-Type": "application/json",
                      'CSRF-Token': csrfToken
                  },
                  body: JSON.stringify({ order: { status, number } }),
              });
              if (response.status === 200) {
                setSuccessAlert('block')
                setLoader(false);
              } else {
                  console.error("Failed");
                  setErrorAlert('block')
                  setLoader(false);
              }
          } catch (error) {
              console.error("Error:", error);
              setLoader(false);
              setErrorAlert('block')
          }
      };

      if(formData === null) {
        return (
             <ThemeProvider key="theme-provider" theme={defaultTheme}>
                 <Container key="main-container" component="main" maxWidth="xs">
                 <Box
                     key="box"
                     sx={{
                         marginTop: 8,
                         display: 'flex',
                         flexDirection: 'column',
                         alignItems: 'center',
                     }}
                 >
                         <Avatar key="avatar" sx={{ m: 1, bgcolor: 'green' }}>
                             <ReceiptLongIcon />
                         </Avatar>
                         <CircularIndeterminate display={true}/>
                     </Box>
                 </Container>
             </ThemeProvider>
         )
     }

    return (
        <div key="alert-container">
        <ThemeProvider key="theme-provider" theme={defaultTheme}>
            <SimpleAlert key="message1-alert" message="Order updated" type="success" display={successAlert}/>
            <SimpleAlert key="message2-alert" message="Error updating order" type="error" display={errorAlert}/>
            <Container key="main-container" component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    key="box"
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Avatar key="avatar" sx={{ m: 1, bgcolor: "green" }}>
                        <ReceiptLongIcon />
                    </Avatar>
                    <Typography key="sign-in-title" component="h1" variant="h5">
                        Order #{formData.number}
                    </Typography>
                    <Box key="form-box" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            key="username-input"
                            margin="normal"
                            required
                            fullWidth
                            id="status"
                            label="Status"
                            name="status"
                            autoComplete="status"
                            autoFocus
                            value={formData.status}
                            onChange={handleChange}
                        />
                        <TextField
                            key="password-input"
                            margin="normal"
                            required
                            fullWidth
                            name="number"
                            label="Number"
                            id="number"
                            autoComplete="current-number"
                            value={formData.number}
                            onChange={handleChange}
                        />
                        <Button
                            key="sign-in-button"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: "green" }}
                        >
                            Save
                        </Button>
                    </Box>
                    <div style={{marginTop: '30px'}}>
                        <CircularIndeterminate key="loading-spinner" display={loader}/>
                    </div>
                </Box>
            </Container>
        </ThemeProvider>
        <div id="table">
            <Table bordered>
                <thead>
                    <tr>
                        <th className='cart-th' style={{ borderRight: 'none' }}>Order products</th>
                        <th style={{ borderLeft: 'none', borderRight: 'none' }}></th>
                        <th style={{ borderLeft: 'none', borderRight: 'none' }}></th>
                        <th style={{ borderLeft: 'none' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {formData.products.map((product) => (
                        <tr id={`product-${product.product._id}`} key={`product-${product.product._id}`}>
                            <td style={{borderRight: 'none'}}>
                                <img alt='product' className='productImg' src={product.product.images[0].url} style={{marginRight: '10px'}}/>
                            </td>
                            <td className='productName' style={{ borderRight: 'none', borderLeft: 'none' }}>
                                <Link to={`/product/${product.product._id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                    {product.product.english_name}
                                </Link>
                            </td>
                            <td style={{ borderLeft: 'none', borderRight: 'none' }}>
                                Qty: {product.nb_ordered}
                            </td>
                            <td className='productPrice' style={{textAlign: 'center', borderLeft: 'none'}}>${product.product.price}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    </div>
    )
}