import '../css/CategoryPage.css';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ProductItem from "../components/ProductItem";
import Cookies from 'js-cookie';
import CircularIndeterminate from '../components/CircularLoad';
import SimpleAlert from '../components/SimpleAlert';
import SessionAlert from '../components/SessionAlert'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export default function CategoryPage() {
    const { category } = useParams();
    const [data, setData] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [language, setLanguage] = useState('english');
    const [options, setOptions] = useState([]);
    const [error, setError] = useState(null);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const productsPerPage = 12;

    useEffect(() => {
        fetchInitialProducts();
        fetchTotalProducts();
    }, [category]);

    useEffect(() => {
        fetchProductsByPage(currentPage);
    }, [currentPage]);

    const fetchInitialProducts = async () => {
        try {
            if (Cookies.get('language')) {
                setLanguage(Cookies.get('language'));
            } else {
                Cookies.set('language', 'english');
                setLanguage('english');
            }
            await fetchProductsByPage(1);
        } catch (error) {
            console.error('Error fetching initial products:', error);
            setError('Failed to fetch initial products.');
        }
    };

    const fetchTotalProducts = async () => {
        try {
            const response = await fetch(`https://pharmacy.hadikaraki.net/api/product/category-count/${category}`);
            if (response.ok) {
                const { count } = await response.json();
                setTotalProducts(count);
                setTotalPages(Math.ceil(count / productsPerPage));
            } else {
                console.error('Failed to fetch total product count');
                setError('Failed to fetch total product count.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to fetch total product count.');
        }
    };

    const fetchProductsByPage = async (page) => {
        setLoading(true);
        if(data.length > 0) {
            setData([])
        }
        try {
            const response = await fetch(`https://pharmacy.hadikaraki.net/api/product/category/${category}?page=${page}&limit=${productsPerPage}`);
            if (response.ok) {
                const products = await response.json();
                console.log(products)
                const uniqueBrands = [...new Set(products.map(product => product.brand))];
                const newOptions = uniqueBrands.map(brand => ({
                    label: brand,
                    value: brand
                }));
                setLoading(false);
                setOptions(newOptions);
                setData(products);
            } else {
                console.error('Failed to fetch products');
                setError('Failed to fetch products.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to fetch products.');
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        setSelectedBrands(prevSelectedBrands =>
            prevSelectedBrands.includes(value)
                ? prevSelectedBrands.filter(brand => brand !== value)
                : [...prevSelectedBrands, value]
        );
    };

    const filteredData = data.filter(product =>
        selectedBrands.length === 0 || selectedBrands.includes(product.brand)
    );

    const categories = {
        'pain_killers': (language === 'arabic') ? 'مسكنات الألم' : 'Pain killers',
        'vitamins': (language === 'arabic') ? 'الفيتامينات' : 'Vitamins',
        'colds': (language === 'arabic') ? 'سعال البرد' : 'Cold/cough',
        'body_care': (language === 'arabic') ? 'العناية بالجسم' : 'Body care',
        'hygiene': (language === 'arabic') ? 'صحة' : 'Hygiene',
        'first_aid': (language === 'arabic') ? 'إسعافات أولية' : 'First aid',
    };

    return (
        <div dir={language === 'arabic' ? 'rtl' : 'ltr'} id='categorypage-container'>
            <SessionAlert />
            {/* <h2 id='category-h2'>{categories[category]}</h2> */}
            <section id="big-category-container">
                <SimpleAlert id="addcart-alert" key="alert" message="Added to cart" type='success' />
                <aside id="listAside">
                    <h5 style={{ color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300' }}>
                        {language === 'arabic' ? 'فئات' : 'Categories'}
                    </h5>
                    <ul>
                        {Object.entries(categories).map(([catKey, catName]) => (
                            <li key={catKey}>
                                <a href={`/product/category/${catKey}`} style={{ fontWeight: category === catKey ? 'bold' : '100' }}>
                                    {catName}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <hr />
                    <div id="brand-filter">
                        <h5 style={{ color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300' }}>
                            {language === 'english' ? 'Brands' : 'الماركات'}
                        </h5>
                        <CircularIndeterminate display={loading} />
                        <FormGroup style={{ margin: '0px' }}>
                            {options.map(option => (
                                <FormControlLabel
                                    key={option.value}
                                    color='success'
                                    style={{ margin: '0px' }}
                                    control={
                                        <Checkbox
                                            checked={selectedBrands.includes(option.value)}
                                            onChange={handleCheckboxChange}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </FormGroup>
                    </div>
                </aside>
                <aside id="productsAside">
                    <div className="category-container">
                        {Array.from({ length: totalProducts }).map((_, index) => (
                            <Box key={index} sx={{ pt: 0.5, display: loading ? 'block' : 'none' }}>
                                <Skeleton variant="rectangular" width={210} height={118} />
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        ))}
                        {error ? (
                            <div>Error: {error}</div>
                        ) : (
                            filteredData.map(product => (
                                <ProductItem key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={language}/>
                            ))
                        )}
                    </div>
                    <div id='category-pagination'>
                        <Stack spacing={2}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                variant="outlined"
                                color="primary"
                            />
                        </Stack>
                    </div>
                </aside>
            </section>
        </div>
    );
}