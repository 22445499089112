import '../css/Brand.css';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ProductCard from "../components/ProductCard";
import Cookies from 'js-cookie';
import SimpleAlert from '../components/SimpleAlert';
import CircularIndeterminate from '../components/CircularLoad';

export default function Brand() {
    const { brand } = useParams();
    const [data, setData] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showMoreDisplay, setShowMoreDisplay] = useState(false);
    const [language, setLanguage] = useState('english');
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchInitialProducts();
    }, [brand]);

    const fetchInitialProducts = async () => {
        try {
            if (Cookies.get('language')) {
                setLanguage(Cookies.get('language'));
            } else {
                Cookies.set('language', 'english');
                setLanguage('english');
            }
            await fetchProductsByBrand(brand);
        } catch (error) {
            console.error('Error fetching initial products:', error);
            setError('Failed to fetch initial products.');
        }
    };

    const fetchProductsByBrand = async (brand) => {
        try {
            const response = await fetch(`https://pharmacy.hadikaraki.net/api/brand/${brand}?totalProducts=${totalProducts}`);
            if (response.ok) {
                const products = await response.json();
                setLoading(false);
                setTotalProducts(prevTotalProducts => prevTotalProducts + products.length);
                setData(prevData => [...prevData, ...products]);
                setShowMoreDisplay(false)
            } else {
                console.error('Failed to fetch products');
                setError('Failed to fetch products.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to fetch products.');
        }
    };  

    const categories = {
        'pain_killers': (language === 'arabic') ? 'مسكنات الألم' : 'Pain killers',
        'vitamins': (language === 'arabic') ? 'الفيتامينات' : 'Vitamins',
        'colds': (language === 'arabic') ? 'سعال البرد' : 'Cold/cough',
        'body_care': (language === 'arabic') ? 'العناية بالجسم' : 'Body care',
        'hygiene': (language === 'arabic') ? 'صحة' : 'Hygiene',
        'first_aid': (language === 'arabic') ? 'إسعافات أولية' : 'First aid'
    };

    return (
        <div>
            <h2 id='brand-h2'>{brand}</h2>
            <section id="big-brand-container">
                <SimpleAlert id="addcart-alert" key="alert" message="Added to cart" type='success'/>
                <aside id="listAside">
                    <div id="list">
                        <h4>Categories</h4>
                        <ul>
                            {Object.entries(categories).map(([catKey, catName]) => (
                                <li key={catKey}>
                                    <a href={`/product/category/${catKey}`} style={{ fontWeight: brand === catKey ? 'bold' : 'normal' }}>
                                        {catName}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </aside>
                <aside id="productsAside">
                    <div className="brand-container">
                        <CircularIndeterminate display={loading}/>
                        {error ? (
                            <div>Error: {error}</div>
                        ) : (
                            data.map(product => (
                                <ProductCard key={product._id} product={product} language={language}/>
                            ))
                        )}
                        <CircularIndeterminate display={showMoreDisplay}/>
                    </div>
                </aside>
            </section>
        </div>
    );
}