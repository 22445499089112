import '../css/AllOrders.css'
import * as React from 'react';
import { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import Button from '@mui/material/Button';
import CircularIndeterminate from '../components/CircularLoad';
import { Link } from 'react-router-dom';

export default function AllOrder() {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredData = data.filter(order =>
        order.number.toString().includes(searchTerm)
    );

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://pharmacy.hadikaraki.net/api/order/all');
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    if(data.length === 0) {
        return (
            <div id="table">
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Order #</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <CircularIndeterminate display={true}/>
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <div id="table">
            <Table bordered>
                <thead>
                    <tr>
                        <th>Order #</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>
                            <input
                            type="text"
                            placeholder="Search by Order Number"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                {filteredData.map(order => (
                    <tr key={`order-${order._id}`}>
                    <td>{order.number}</td>
                    <td>{order.status}</td>
                    <td>{order.date}</td>
                    <td>
                        <Link
                        to={`/order/edit/${order._id}`}
                        style={{ color: 'inherit', textDecoration: 'none' }}
                        >
                        <Button variant="outlined">Edit</Button>
                        </Link>
                    </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    )
}