export const addToWishlist = async (productID, csrfToken) => {
    try {
        const response = await fetch('http://backend-service:5006/user/addToWishlist', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'CSRF-Token': csrfToken
            },
            body: JSON.stringify({ productID })
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};