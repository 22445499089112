import '../css/Footer.css'
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import appStore from '../assets/app-store.jpg';
import googlePlay from '../assets/google-play.jpg';
import visa from '../assets/visa.png';
import mastercard from '../assets/mastercard.png';
import americanexpress from '../assets/americanexpress.PNG';

const defaultTheme = createTheme();

export default function Footer() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box id="footer-muibox" sx={{display: 'flex', flexDirection: 'column'}}>
        <CssBaseline />
        <Box component="footer" sx={{py: 3, px: 2, mt: 'auto' }}>
          <Container maxWidth="inherit">
            <Typography component="div" variant="body1">
                <section id="footer-container">
                    <div id="box">
                        <p style={{ fontSize: '20px', fontWeight: 'bold', color: 'gold' }}>&copy; 2024 Pharmacy</p>
                        <p>Contact us</p>
                        <p>Phone: +961 78857330</p>
                        <p>Email: hadikaraki373@gmail.com</p>
                        <i className="fa fa-whatsapp" id='social-media' style={{ color: '#128C7E' }}></i>
                        <i className="fa fa-facebook-f" id='social-media' style={{ color: '#4267B2', marginLeft: '10px' }}></i>
                        <i className="fa fa-instagram" id='social-media' style={{ color: '#C13584', marginLeft: '10px' }}></i>
                        <i className="fa fa-twitter" id='social-media' style={{ color: '#1DA1F2', marginLeft: '10px' }}></i>
                    </div>
                    <div id="box">
                        <p style={{ marginBottom: '5px', fontWeight: 'bold', fontSize: '20px' }}>Know us</p>
                        <p>About us</p>
                        <p>Are you a pharmacy owner?</p>
                    </div>
                    <div id="box">
                        <p style={{ marginBottom: '5px', fontWeight: 'bold', fontSize: '20px' }}>Download our App</p>
                        <p><img src={appStore} alt='appstore' style={{ height: '50px', width: '140px', borderRadius: '10px' }} /></p>
                        <p><img src={googlePlay} alt='googleplay' style={{ height: '50px', width: '140px', marginTop: '10px', borderRadius: '10px' }} /></p>
                    </div>
                    <div id="box">
                        <p style={{ marginBottom: '5px', fontWeight: 'bold', fontSize: '20px' }}>Secure payment methods</p>
                        <p>
                          <img src={visa} alt='visa' style={{ height: '40px', width: '80px', marginTop: '10px', borderRadius: '10px' }} />
                          <img src={mastercard} alt='mastercard' style={{ height: '40px', width: '80px', marginTop: '10px', marginLeft: '10px', borderRadius: '10px' }} />
                          <img src={americanexpress} alt='americanexpress' style={{ height: '40px', width: '80px', marginTop: '10px', marginLeft: '10px', borderRadius: '10px' }} />
                        </p>
                    </div>
                </section>
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}