import '../css/ProductCard.css'
import * as React from 'react';
import { addToCart } from "../utils/addToCart";
import { addToWishlist } from "../utils/addToWishlist";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
//   })(({ theme, expand }) => ({
//     transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//     marginLeft: 'auto',
//     transition: theme.transitions.create('transform', {
//       duration: theme.transitions.duration.shortest,
//     }),
// }));

export default function ProductCard({product, language}) {
  const navigate = useNavigate();

  const handleAddToWishlist = async () => {
      const response = await addToWishlist(product._id);
      if (response.ok) {
          // Success: Do something
      } else if (response.status === 401) {
          navigate('/user/login')
      }
      else if (response.status === 403) {
        alert('Product already in wishlist')
    }
  };

  return (
    <Card id="product-card" sx={{ maxWidth: 345, marginBottom: "15px" }}>
      <Link to={`/product/${product._id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
        <CardMedia
          component="img"
          height="150"
          image={`${product.images[0].url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')}`}
          srcSet={`
            ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_200,q_auto,f_auto/')} 200w, 
            ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_400,q_auto,f_auto/')} 400w, 
            ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')} 800w
          `}
          sizes="(max-width: 600px) 200px, (max-width: 1200px) 400px, 800px"
          alt='Product image'
          style={{objectFit: 'cover', height: 'auto'}}
        />
        <CardContent  style={{paddingBottom: '0'}}>
          <Typography variant="body2" color="text.secondary" style={{fontSize: '15px'}}>
            {language === 'english' ? product.english_name : product.arabic_name}
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
            {language == 'english' ? product.english_description : product.arabic_description}
          </Typography> */}
        </CardContent>
      </Link>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites" onClick={handleAddToWishlist}>
          <FavoriteIcon sx={{color: "green"}}/>
        </IconButton>
        <IconButton aria-label="share" onClick={() => addToCart(product._id, product.english_name, product.price, product.category, 1)}>
          <AddShoppingCartIcon sx={{color: "green"}}/>
        </IconButton>
      </CardActions>
    </Card>
  );
}