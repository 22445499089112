import * as React from 'react';
import { useState } from "react";
import { useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from "react-hook-form";
import CircularIndeterminate from '../components/CircularLoad';

const defaultTheme = createTheme();

export default function NewProduct() {
    const [loader, setLoader] = useState(false);
    const [productsResults, setProductsResults] = React.useState([]);
    const [productTerm, setProductTerm] = React.useState('');
    const { storeId } = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const handleAddProduct = async (formData) => {
      setLoader(true);
      const { english_name, arabic_name, price, brand, code, arabic_description, english_description, category, nb_in_stock, images } = formData;
      const productData = {
        product: {
          english_name,
          arabic_name,
          english_description,
          arabic_description,
          category,
          brand,
          code,
          price,
          nb_in_stock
        }
      };
    
      // Convert FileList to array
      const imageFiles = Array.from(images);
    
      const removeBackground = async (image) => {
        const formData = new FormData();
        formData.append('image_file', image);
        formData.append('size', 'auto');
    
        try {
          const response = await fetch('https:/https://pharmacy.hadikaraki.net/api.remove.bg/v1.0/removebg', {
            method: 'POST',
            headers: {
              'X-Api-Key': 'ee2EYtrbeAomRxfCUgiGMuei',
            },
            body: formData
          });
    
          if (!response.ok) {
            throw new Error('Failed to remove background');
          }
    
          const blob = await response.blob();
          return new File([blob], image.name, { type: blob.type });
        } catch (error) {
          console.error('Error removing background:', error);
          throw new Error('Background removal failed');
        }
      };
    
      try {
        const processedImages = await Promise.all(imageFiles.map(removeBackground));
    
        const form = new FormData();
        // Append each processed image file
        for (let i = 0; i < processedImages.length; i++) {
          form.append('images', processedImages[i]);
        }
    
        // Append each product field
        Object.entries(productData.product).forEach(([key, value]) => {
          form.append(`product[${key}]`, value);
        });
    
        // Append storeId separately
        form.append('storeId', storeId);
    
        const response = await fetch('https://pharmacy.hadikaraki.net/api/product/new', {
          method: 'POST',
          body: form
        });
    
        if (response.ok) {
          // Handle success
        } else {
          console.error('Failed to add product');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    
      setLoader(false);
    };

    const handleProductsResults = async (event) => {
      const value = event.target.value;
      if(value.length === 0) {
        setProductsResults([])
      }
      setProductTerm(value);
      if (value.length >= 2) {
        try {
          const response = await fetch(`https://pharmacy.hadikaraki.net/api/autocomplete?name=${value}`);
          if (response.ok) {
            const data = await response.json();
            setProductsResults(data);
          } else {
            console.error('Failed to fetch autocomplete results');
          }
        } catch (error) {
          console.error('Error fetching autocomplete results:', error);
        }
      } else {
        setProductsResults([]);
      }
    };
  
    return (
      <div id='newproduct-container' style={{marginTop: '5%'}}>
          <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '20px'}}>
                <CssBaseline />
                  <Box
                  sx={{
                      marginTop: 5,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                  }}
                  >
                  <Avatar sx={{ m: 1, bgcolor: 'green' }}>
                      <AddIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                      Add product
                  </Typography>
                  <Box component="form" noValidate onSubmit={handleSubmit(handleAddProduct)} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="english_name"
                          label="English name"
                          name="english_name"
                          value={productTerm}
                          // onChange={handleProductsResults}
                          {...register("english_name", { required: "English name cannot be blank" })}
                        />
                        {/* <ul key='newproduct-list' id='ulsearch-list'>
                          {productsResults.map((result, index) => (
                            <li key={`newproductsearch-result-${index}`} style={{ color: 'black', fontWeight: '500', backgroundColor: '#fafaf2', padding: '5px' }}>
                              <img key={`newproductresult-img-${index}`} src={result.images[0].url} height='50px' width='50px' style={{ marginRight: '10px' }}></img> {result.english_name}
                            </li>
                          ))}
                        </ul> */}
                        <small className="text-danger">
                          {errors?.english_name && errors.english_name.message}
                        </small>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="arabic_name"
                          label="Arabic name"
                          name="arabic_name"
                          {...register("arabic_name", { required: "Arabic name cannot be blank" })}
                        />
                        <small className="text-danger">
                          {errors?.arabic_name && errors.arabic_name.message}
                        </small>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="english_description"
                          label="English Description"
                          name="english_description"
                          {...register("english_description", { required: "English description cannot be blank" })}
                        />
                        <small className="text-danger">
                          {errors?.english_description && errors.english_description.message}
                        </small>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="arabic_description"
                          label="Arabic Description"
                          name="arabic_description"
                          {...register("arabic_description", { required: "Arabic description cannot be blank" })}
                        />
                        <small className="text-danger">
                          {errors?.arabic_description && errors.arabic_description.message}
                        </small>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="brand-product"
                          label="Brand"
                          name="brand"
                          {...register("brand", { required: "Brand cannot be blank" })}
                        />
                        <small className="text-danger">
                          {errors?.brand && errors.brand.message}
                        </small>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="price"
                          required
                          fullWidth
                          id="price"
                          label="Price"
                          autoFocus
                          {...register("price", { required: "Price cannot be blank" })}
                        />
                        <small className="text-danger">
                          {errors?.price && errors.price.message}
                        </small>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="code"
                          label="Code"
                          name="code"
                          {...register("code", { required: "Code cannot be blank" })}
                        />
                        <small className="text-danger">
                          {errors?.code && errors.code.message}
                        </small>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="nb_in_stock"
                          label="Quantity"
                          name="nb_in_stock"
                          {...register("nb_in_stock", { required: "Quantity cannot be blank" })}
                        />
                        <small className="text-danger">
                          {errors?.nb_in_stock && errors.nb_in_stock.message}
                        </small>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            type="file"
                            id="images"
                            name="images"
                            inputProps={{ multiple: true }}
                            {...register("images")}
                          />
                          <small className="text-danger">
                            {errors?.images && errors.images.message}
                          </small>
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, bgcolor: "green" }}
                    >
                      Add product
                    </Button>
                    <Grid container justifyContent="flex-end"></Grid>
                  </Box>
                  <div style={{marginTop: '20px', marginBottom: '20px'}}>
                      <CircularIndeterminate key="loading-spinner" display={loader}/>
                  </div>
                </Box>
            </Container>
          </ThemeProvider>
        </div>
    );
}