import '../css/UpdateAddress.css'
import * as React from 'react';
import { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleAlert from '../components/SimpleAlert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import { useForm } from "react-hook-form";
import useCsrfToken from '../customHooks/useCsrfToken';

const defaultTheme = createTheme();

export default function UpdateAddress(){
    const { index } = useParams();
    const [address, setAddress] = useState(null);
    const [loader, setLoader] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');
    const csrfToken = useCsrfToken();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
    
    const addressOptions = {
        street: { required: "Street cannot be blank" },
        state: { required: "State cannot be blank" },
        city: { required: "City cannot be blank" },
        building: { required: "Building cannot be blank" },
        floor: { required: "Floor cannot be blank" }
    };

    useEffect(() => {
        fetchAddress();
    }, []);

    const fetchAddress = async () => {
        setLoader(true)
        try {
            const response = await fetch(`https://pharmacy.hadikaraki.net/api/user/get-address/${index}`);
            const data = await response.json();
            if (data.success) {
                setAddress(data.address);
                setLoader(false);
            } else {
                // notify user
            }
        } catch (error) {
            // notify user
        }
    };

    const handleAddressUpdate = async (formData) => {
        setLoader(true);
        try {
            const { street, state, city, building, floor, extra_info } = formData;
            const response = await fetch(`https://pharmacy.hadikaraki.net/api/user/update-address/${index}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'CSRF-Token': csrfToken
                },
                body: JSON.stringify({ address: { street, state, city, building, floor, extra_info } }),
            });
            const data = await response.json();
            setAlertMsg(data.message)
            if (data.success) {
                setAlertType('success')
            } else {
                setAlertType('error')
            }
        } catch (error) {
            setAlertType('error')
            setAlertMsg("An error occured")
        }
        setLoader(false);
    };

    const handleDelete = async (event) => {
        event.preventDefault();
        setLoader(true);
        try {
            const response = await fetch(`https://pharmacy.hadikaraki.net/api/user/delete-address/${index}`, {
                method: "DELETE",
                headers: {
                    'CSRF-Token': csrfToken
                }
            });
            const data = await response.json();
            if (data.success) {
                navigate('/user/addresses')
                window.location.reload();
            } else {
                setAlertType('error')
                setAlertMsg(data.message)
            }
        } catch (error) {
            setAlertType('error')
            setAlertMsg("An error occured")
        }
        setLoader(false);
    };
    
    if(address === null) {
        return (
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
            <Container key="main-container" component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    key="box"
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Avatar key="avatar" sx={{ m: 1, bgcolor: "green" }}>
                        <HomeIcon />
                    </Avatar>
                    <Typography key="sign-in-title" component="h1" variant="h5">
                        Update address
                    </Typography>
                    <Box key="form-box" component="form" noValidate sx={{ mt: 1 }}>
                        <CircularIndeterminate key="loading-spinner" display={loader}/>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
        )
    }

    return (
        <div id='updateaddress-bigcontainer' style={{marginTop: '5%'}}>
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <SimpleAlert message={alertMsg} type={alertType} display={alertMsg !== '' ? 'block' : 'none'}/>
                <Container className='updateaddress-container' key="main-container" component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '20px'}}>
                    <CssBaseline />
                    <Box
                        key="box"
                        sx={{
                            marginTop: 5,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: "green" }}>
                            <HomeIcon />
                        </Avatar>
                        <Typography key="sign-in-title" component="h2" variant="h5">
                            Update address
                        </Typography>
                        <Box key="form-box" component="form" onSubmit={handleSubmit(handleAddressUpdate)} noValidate sx={{ mt: 3 }}>
                            <Grid key="grid-container" container spacing={2}>
                                <Grid key="cvv-field" item xs={12} sm={6}>
                                    <TextField
                                        key="street-input"
                                        required
                                        fullWidth
                                        name="street"
                                        label="Street"
                                        id="street"
                                        defaultValue={address.street}
                                        {...register("street", addressOptions.street)}
                                    />
                                    <small className="text-danger">
                                        {errors?.street && errors.street.message}
                                    </small>
                                </Grid>
                                <Grid key="state-field" item xs={12} sm={6}>
                                    <TextField
                                        key="state-input"
                                        required
                                        fullWidth
                                        name="state"
                                        label="State"
                                        id="state"
                                        defaultValue={address.state}
                                        {...register("state", addressOptions.state)}
                                    />
                                    <small className="text-danger">
                                        {errors?.state && errors.state.message}
                                    </small>
                                </Grid>
                                <Grid key="city-field" item xs={12} sm={6}>
                                    <TextField
                                        key="city-input"
                                        required
                                        fullWidth
                                        name="city"
                                        label="City"
                                        id="city"
                                        defaultValue={address.city}
                                        {...register("city", addressOptions.city)}
                                    />
                                    <small className="text-danger">
                                        {errors?.city && errors.city.message}
                                    </small>
                                </Grid>
                                <Grid key="building-field" item xs={12} sm={6}>
                                    <TextField
                                        key="building-input"
                                        required
                                        fullWidth
                                        name="building"
                                        label="Building"
                                        id="building"
                                        defaultValue={address.building}
                                        {...register("building", addressOptions.building)}
                                    />
                                    <small className="text-danger">
                                        {errors?.building && errors.building.message}
                                    </small>
                                </Grid>
                                <Grid key="floor-field" item xs={12} sm={6}>
                                    <TextField
                                        key="floor-input"
                                        required
                                        fullWidth
                                        name="floor"
                                        label="Floor"
                                        id="floor"
                                        defaultValue={address.floor}
                                        {...register("floor", addressOptions.floor)}
                                    />
                                    <small className="text-danger">
                                        {errors?.floor && errors.floor.message}
                                    </small>
                                </Grid>
                                <Grid key="extrainfo-field" item xs={12} sm={6}>
                                    <TextField
                                        key="extrainfo-input"
                                        fullWidth
                                        name="extra_info"
                                        label="Extra info"
                                        id="extra_info"
                                        defaultValue={address.extra_info}
                                        {...register("extra_info", addressOptions.extra_info)}
                                    />
                                    <small className="text-danger">
                                        {errors?.extra_info && errors.extra_info.message}
                                    </small>
                                </Grid>
                                <Grid key="updateaddr-field" item xs={12} sm={6}>
                                    <Button
                                        key="updateaddr-button"
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mb: 1, bgcolor: "green" }}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                                <Grid key="deleteaddr-field" item xs={12} sm={6}>
                                    <Button
                                        key="deleteaddr-button"
                                        type="button"
                                        onClick={handleDelete}
                                        fullWidth
                                        variant="contained"
                                        sx={{ mb: 1, bgcolor: "red" }}
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <div style={{marginTop: '30px'}}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                        </div>
                    </Box>
                </Container>
            </ThemeProvider>
        </div>
    )
}