import '../css/Addresses.css'
import { useState, useEffect } from 'react';
import CircularIndeterminate from '../components/CircularLoad';
import SessionAlert from '../components/SessionAlert';
import useCsrfToken from '../customHooks/useCsrfToken';
import SimpleAlert from '../components/SimpleAlert';

export default function Addresses(){
    const [addresses, setAddresses] = useState(null);
    const [phoneNb, setPhoneNb] = useState(null);
    const [loading, setLoading] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');
    const csrfToken = useCsrfToken();

    useEffect(() => {
        fetchAddresses();
    }, []);

    const fetchAddresses = async () => {
        try {
            const response = await fetch('https://pharmacy.hadikaraki.net/api/user/get-addresses');
            const data = await response.json();
            if (data.success) {
                setLoading(false);
                setAddresses(data.addresses);
                setPhoneNb(data.phoneNb)
            } else {
                // setError('Failed to fetch products.');
            }
        } catch (error) {
            // setError('Failed to fetch products.');
        }
    };

    const handleDelete = async (index) => {
        try {
            const response = await fetch(`https://pharmacy.hadikaraki.net/api/user/delete-address/${index}`, {
                method: "DELETE",
                headers: {
                    'CSRF-Token': csrfToken
                }
            });
            const data = await response.json();
            setAlertMsg(data.message)
            if (data.success) {
                setAlertType('success');
                fetchAddresses();
            } else {
                setAlertType('error');
            }
        } catch (error) {
            setAlertMsg("An error occured")
            setAlertType('error');
        }
    };

    if(addresses === null) {
        return (
            <div id="secondary-loading">
                <CircularIndeterminate display={true}/>
            </div>
        )
    }

    return (
        <div>
            <SessionAlert/>
            <SimpleAlert message={alertMsg} type={alertType} display={alertMsg !== '' ? 'block' : 'none'}/>
            <h2 id="addaddress-h2">Addresses</h2>
            <section id='addresses-container'>
                {addresses && addresses.map((address, index) => (
                <div id="address">
                    <h5 style={{textAlign: 'center', marginBottom: '15px'}}>Address {index + 1}</h5>
                    <div id="address-city">
                        <p className='address-p'>{address.city}</p>
                    </div>
                    <div id="address-street">
                        <p className='address-p'>{address.street}</p>
                    </div>
                    <div id="address-building">
                        <p className='address-p'>{address.building}</p>
                    </div>
                    <div id="phone-nb">
                        <p className='address-p'>Phone: {phoneNb}</p>
                    </div>
                    <div id='address-btns'>
                        <a href={`/user/address/${index}`} style={{textDecoration: 'none'}}>
                            <button id='addressedit-btn'>EDIT</button>
                        </a>
                        <button id='addressdelete-btn' onClick={()=>handleDelete(index)}>DELETE</button>
                    </div>
                </div>
                ))}
                <a href="/user/add-address" style={{textDecoration: 'none'}}>
                    <div id="addaddress-box">
                        <div id='inner-box'>
                            <p style={{margin: '0'}}><i className="fa fa-plus" aria-hidden="true" style={{fontSize: '20px'}}></i></p>
                            <p style={{margin: '0'}}>New address</p>
                        </div>
                    </div>
                </a>
            </section>
        </div>
    )
}