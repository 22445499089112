import '../css/SignIn.css'
import * as React from 'react';
import { useNavigate  } from 'react-router-dom';
import { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { TextField, InputAdornment } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import SimpleAlert from '../components/SimpleAlert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SessionAlert from '../components/SessionAlert';
import LoginIcon from '@mui/icons-material/Login';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn({message, type, alertDisplay}) {
    const [loader, setLoader] = useState(false);
    const [emailFound, setEmailFound] = useState('none');
    const [emailNotFound, setEmailNotFound] = useState('none');
    const [open, setOpen] = React.useState(false);
    const [rememberMe, setRememberMe] = React.useState(null);
    const [forgotpassLoader, setForgotpassLoader] = React.useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const loginOptions = {
        username: {
            required: "Username or email is required",
            pattern: {
                value: /^(?=.{3,30}$)(?:[a-zA-Z0-9]+|(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))$/,
                message: "Enter a valid username (3-30 characters) or email"
            }
        },
        password: {
            required: "Password is required",
            pattern: {
                value: /^[a-zA-Z0-9]{8,30}$/,
                message: "Password must be 8-30 characters long and alphanumeric"
            }
        }
    };    

    const handleClickOpen = () => {
        setEmailFound('none')
        setEmailNotFound('none')
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();

    const handleLogin = async (formData) => {
        setLoader(true);
        setAlertMsg('');
        let data;
        try {
            const response = await fetch("https://pharmacy.hadikaraki.net/api/user/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ ...formData, remember: rememberMe }),
                credentials: 'include', // Send cookies with the request
            });
            data = await response.json();
            if (data.success) {
                sessionStorage.setItem('isAuthenticated', 'true');
                sessionStorage.setItem('sessionMessage', data.message);
                navigate("/");
                window.location.reload();
            } else {
                setAlertType('error')
                setAlertMsg(data.message);
            }
        } catch (error) {
            setAlertType('error')
            setAlertMsg('An error occured');
        }
        setLoader(false);
    };    
    
    const handleError = (errors) => {};

    const handleForgotPass = async (event) => {
        event.preventDefault();
        setForgotpassLoader(true)
        setEmailFound('none')
        setEmailNotFound('none')
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const email = formJson.email;
        await fetch('https://pharmacy.hadikaraki.net/api/user/changePassEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email})
        })
        .then(response => {
            if (response.status === 200) {
                setEmailFound('block')
            } else if (response.status === 401) {
                setEmailNotFound('block')
                response.text().then(errorMessage => {
                    console.error(`Server error: ${errorMessage}`);
                });
            } else {
                console.error(`Server error: ${response.status}`);
            }
        })
        .catch(error => {
            console.error('Fetch error:', error);
        });
        setForgotpassLoader(false)
    }

    const handleRememberMe = (event) => {
        setRememberMe(event.target.checked ? 'remember' : null);
        console.log(event.target.checked)
    };

    return (
        <div id="signin-bigcontainer">
            <SessionAlert/>
            <SimpleAlert message={alertMsg} type={alertType} display={alertMsg !== '' ? 'block' : 'none'}/>
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <Container className='signin-container' key="main-container" component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '20px'}}>
                    <CssBaseline />
                    <Box
                        key="box"
                        sx={{
                            marginTop: 5,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: "green" }}>
                            <LoginIcon />
                        </Avatar>
                        <Typography key="sign-in-title" component="h2" variant="h5">
                            Sign in
                        </Typography>
                        <Box key="form-box" component="form" onSubmit={handleSubmit(handleLogin, handleError)} noValidate sx={{ mt: 1 }}>
                            <TextField
                                key="username-input"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username or email"
                                name="username"
                                placeholder='Enter username or email'
                                {...register("username", loginOptions.username)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon style={{color: 'green'}}/>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <small className="text-danger">
                                {errors?.username && errors.username.message}
                            </small>
                            <TextField
                                key="password-input"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                placeholder='Enter your password'
                                {...register("password", loginOptions.password)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOutlinedIcon style={{color: 'green'}}/>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <div>
                                <small className="text-danger">
                                    {errors?.password && errors.password.message}
                                </small>
                            </div>
                            <FormControlLabel
                                key="remember-me-checkbox"
                                control={<Checkbox name="remember" value="remember" color="primary" onChange={handleRememberMe}/>}
                                label="Remember me"
                            />
                            <Button
                                key="sign-in-button"
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: "green" }}
                            >
                                Sign In
                            </Button>
                            <Grid container key="grid-container">
                                <Grid item xs key="forgot-password-link">
                                    <Typography component="div" variant="body2">
                                        <Link id="forgotpass-link" onClick={handleClickOpen} style={{color: 'green'}}>
                                            Forgot password?
                                        </Link>
                                    </Typography>
                                </Grid>
                                <Grid item key="sign-up-link">
                                    <Typography component="div" variant="body2">
                                        <Link key="wishlist-link" to="/user/signup" style={{ color: 'green' }}>
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <div style={{marginTop: '30px'}}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                        </div>
                    </Box>
                </Container>
            </ThemeProvider>

            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: handleForgotPass
                    }}
                >
                    <DialogTitle>Reset password</DialogTitle>
                    <SimpleAlert key="message-alert" message="Email sent" type="success" display={emailFound} close={false}/>
                    <SimpleAlert key="message-alert" message="Email not registered" type="error" display={emailNotFound} close={false}/>
                    <DialogContent>
                        <DialogContentText>
                            To change the password, enter your email and an email will be sent to your inbox in order to reset your password.
                        </DialogContentText>
                        <TextField
                            required
                            margin="dense"
                            id="name"
                            name="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <div style={{marginTop: '15px', display: 'flex', justifyContent: 'center'}}>
                        <CircularIndeterminate key="loading-spinner" display={forgotpassLoader}/>
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Submit</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    );
}