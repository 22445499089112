import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function SimpleAlert({ message, type, display = "none", close = true }) {
  const [open, setOpen] = React.useState(true);
  const [alertKey, setAlertKey] = React.useState(0);

  React.useEffect(() => {
    if (message) {
      setOpen(true);
      setAlertKey(prevKey => prevKey + 1);
    }
  }, [message]);

  const handleClose = () => {
    setOpen(false);
    sessionStorage.removeItem('sessionMessage');
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={2} style={{ display: open ? display : 'none' }} key={alertKey}>
      <Alert severity={type} onClose={close ? handleClose : null}>
        {message}
      </Alert>
    </Stack>
  );
}