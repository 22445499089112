import '../css/Wishlist.css'
import { useEffect, useState } from "react";
import { addToCart } from "../utils/addToCart";
import { removeFromWishlist, clearWishlist } from "../utils/removeFromWishlist";
import CircularIndeterminate from '../components/CircularLoad';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import useCsrfToken from '../customHooks/useCsrfToken';
import { useWishlist } from '../WishlistContext';
import { getWishlistLength } from "../utils/getWishlistLength";
import { useCart } from '../CartContext';
import { getCartLength } from "../utils/getCartLength";
import Button from '@mui/material/Button';

export default function Wishlist(){
    const [products, setProducts] = useState(null);
    const [alertDisplay, setAlertDisplay] = useState('none');
    const csrfToken = useCsrfToken();
    const { setWishlistLength } = useWishlist();
    const { setCartLength } = useCart();

    useEffect(() => {
        fetchWishlistProducts();
    }, []);
    
    const fetchWishlistProducts = async () => {
        try {
          const response = await fetch('https://pharmacy.hadikaraki.net/api/user/wishlist');
          const data = await response.json();
          if (data.success) {
            let wishlist = data.wishlist
            setProducts(wishlist);
          } else {
            // notify user error
          }
        } catch (error) {
          // notify user error
        }
    };

    function confirmRemove(productID) {
        const confirmation = window.confirm('Are you sure you want to remove this product from your wishlist?');
        if (confirmation) {
            removeProduct(productID);
        }
    }

    const removeProduct = async (productID) => {
        removeFromWishlist(productID, csrfToken, async() => {
            // remove product when response is ok
            setProducts(products.filter(product => product._id !== productID));

            // Fetch the updated cart length
            const wishlistLength = await getWishlistLength();
            setWishlistLength(wishlistLength)
        });
    }

    const handleAddToCart = (productID, productName, productPrice, productCategory) => {
        setAlertDisplay('block');
        addToCart(productID, productName, productPrice, productCategory, 1, csrfToken, async() => {
            removeProduct(productID);
            // Fetch the updated cart length
            const cartLength = await getCartLength();
            setCartLength(cartLength)
        });
    };

    const handleClearWishlist = async () => {
        const confirmation = window.confirm('Are you sure you want to clear your wishlist?');
        if (confirmation) {
            clearWishlist(csrfToken, async() => {
                // remove product when response is ok
                setProducts([]);
                
                // Fetch the updated cart length
                const length = await getWishlistLength();
                setWishlistLength(length)
            });
        }
    };

    if (!products) {
        return (
            <div id="secondary-loading">
                <CircularIndeterminate display={true}/>
            </div>
        )
    }

    if (products.length === 0) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                height: '50vh'
              }}>
                <div style={{padding: '30px', borderRadius: '50%', backgroundColor: '#f0ebeb'}}>
                    <FavoriteIcon style={{color: 'green', fontSize: '60px'}}/>
                </div>
                <h2 style={{marginTop: '10px'}}>Wishlist is empty</h2>
                <a href='/'>
                    <button style={{fontWeight: 'bold', marginTop: '10px', border: '0px', borderRadius: '10px', color: 'white', backgroundColor: '#2cd451', padding: '15px 100px'}}>Lookup Products Now</button>
                </a>
            </div>
        )
    }

    return (
        <div className="wishlist-container">
            <div className="wishlist-info">
                <p>Wishlist</p>
            </div>
            <div className="wishlist-products">
                {products.map((product) => (
                    <div id={`wishlistproduct-${product._id}`} className="wishlistproduct-row" key={product._id}>
                        <div className="wishlistproduct-image">
                            <a href={`/product/${product.id}`}>
                                <img
                                    src={`${product.images[0].url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')}`}
                                    loading="lazy"
                                    srcSet={`
                                        ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_200,q_auto,f_auto/')} 200w,
                                        ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_400,q_auto,f_auto/')} 400w,
                                        ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')} 800w
                                    `}
                                    sizes="(max-width: 600px) 200px, (max-width: 1200px) 400px, 800px"
                                    alt="Product"
                                />
                            </a>
                        </div>

                        <div className="wishlistproduct-name">
                            <a href={`/product/${product.id}`}>
                                <span className="product-brand">{product.brand}</span>
                                <h6>{product.english_name}</h6>
                            </a>
                        </div>

                        <div className="wishlistpurchase-info">
                            <IconButton style={{color: '#00a152', marginRight: '10px'}} color="primary" onClick={() => handleAddToCart(product._id, product.english_name, product.price, product.category)}>
                                <AddShoppingCartIcon className='iconBtn'/>
                            </IconButton>
                            <span style={{marginRight: '10px'}}>${product.price}</span>
                            <IconButton style={{ color: '#00a152' }} onClick={() => confirmRemove(product._id)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                ))}
                <hr></hr>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button id='clearwishlistBtn' onClick={handleClearWishlist} style={{ borderColor: 'green', color: 'green', textTransform: 'none' }} variant="outlined">Clear wishlist</Button>
                </div>
            </div>
        </div>
    );
}