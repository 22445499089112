import '../css/AddStore.css'
import { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import { useForm } from "react-hook-form";

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/">
          Pharmacy
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function AddStore() {
    const [loader, setLoader] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const registerOptions = {
        name: { required: "First name cannot be blank" },
        street: { required: "Street cannot be blank" },
        city: {required: "City cannot be blank"},
        building: {required: "Building cannot be blank"},
        state: {required: "State cannot be blank"},
        phone_nb: {required: "Phone number cannot be blank"}
    };

    const handleAddStore = async (formData) => {
        setLoader(true);
        const { name, street, city, building, state, phone_nb } = formData;
        const storeData = {
            store: { name, street, city, building, state, phone_nb }
        };
        try {
            const response = await fetch("https://pharmacy.hadikaraki.net/api/store/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(storeData)
            });
            if (response.ok) {
                // sessionStorage.setItem('sessionMessage', 'Welcome!');
                // navigate("/");
                // window.location.reload();
            } else {
                console.error("Failed");
            }
        } catch (error) {
            console.error("Error:", error);
        }
        setLoader(false)
    };
    const handleError = (errors) => {};

    return (
        <div id='addstore-container'>
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <Container key="main-container" component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '20px'}}>
                    <CssBaseline />
                    <Box
                        key="box"
                        sx={{
                            marginTop: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: 'green' }}>
                            <AddBusinessIcon />
                        </Avatar>
                        <Typography key="sign-up-title" component="h1" variant="h5">
                            Add store
                        </Typography>
                        <Box key="form-box" component="form" noValidate onSubmit={handleSubmit(handleAddStore, handleError)} sx={{ mt: 3 }}>
                            <Grid key="grid-container" container spacing={2}>
                                <Grid key="name-field" item xs={12}>
                                    <TextField
                                        key="name-input"
                                        name="name"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        autoFocus
                                        {...register("name", registerOptions.name)}
                                    />
                                    <small className="text-danger">
                                        {errors?.name && errors.name.message}
                                    </small>
                                </Grid>
                                {/* <p>Address</p> */}
                                <Grid key="street-field" item xs={12}>
                                    <TextField
                                        key="street-input"
                                        name="street"
                                        required
                                        fullWidth
                                        id="street"
                                        label="Street"
                                        autoFocus
                                        {...register("street", registerOptions.street)}
                                    />
                                    <small className="text-danger">
                                        {errors?.street && errors.street.message}
                                    </small>
                                </Grid>
                                <Grid key="city-field" item xs={12} sm={6}>
                                    <TextField
                                        key="city-input"
                                        required
                                        fullWidth
                                        id="city"
                                        label="City"
                                        name="city"
                                        {...register("city", registerOptions.city)}
                                    />
                                    <small className="text-danger">
                                        {errors?.city && errors.city.message}
                                    </small>
                                </Grid>
                                <Grid key="state-field" item xs={12} sm={6}>
                                    <TextField
                                        key="state-input"
                                        required
                                        fullWidth
                                        id="state"
                                        label="State"
                                        name="state"
                                        {...register("state", registerOptions.state)}
                                    />
                                    <small className="text-danger">
                                        {errors?.city && errors.city.message}
                                    </small>
                                </Grid>
                                <Grid key="building-field" item xs={12} sm={6}>
                                    <TextField
                                        key="building-input"
                                        required
                                        fullWidth
                                        id="building"
                                        label="Building"
                                        name="building"
                                        {...register("building", registerOptions.building)}
                                    />
                                    <small className="text-danger">
                                        {errors?.building && errors.building.message}
                                    </small>
                                </Grid>
                                <Grid key="phonenb-field" item xs={12} sm={6}>
                                    <TextField
                                        key="phonenb-input"
                                        required
                                        fullWidth
                                        name="phone_nb"
                                        label="Phone number"
                                        id="phone_nb"
                                        {...register("phone_nb", registerOptions.phone_nb)}
                                    />
                                    <small className="text-danger">
                                        {errors?.phone_nb && errors.phone_nb.message}
                                    </small>
                                </Grid>
                            </Grid>
                            <Button
                                key="sign-up-button"
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: 'green' }}
                            >
                                Save
                            </Button>
                        </Box>
                        <div style={{marginTop: '30px'}}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                        </div>
                    </Box>
                </Container>
                <Copyright key="Pharmacy" sx={{ mt: 5 }} />
            </ThemeProvider>
        </div>
    );
}