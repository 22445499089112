import '../css/Category.css'
export default function Category({ imgSource, category, label }) {
    return (
        <div id="home-category">
            <a href={`/product/category/${category}`}>
                <img src={imgSource} alt='category'/>
                <h4>{label}</h4>
            </a>
        </div>
    );
}