import '../css/SearchResults.css'
import { useEffect, useState } from "react";
import ProductItem from "../components/ProductItem";
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import SessionAlert from '../components/SessionAlert'

export default function SearchResults() {
    const location = useLocation();
    const searchData = location.state?.searchData;
    const searchValue = location.state?.value;
    const [language, setLanguage] = useState('english');
    
    useEffect(() => {
        if (Cookies.get('language')) {
            setLanguage(Cookies.get('language'));
        } else {
            Cookies.set('language', 'english');
            setLanguage('english');
        }
    }, []);

    const categories = {
        'pain_killers': (language === 'arabic') ? 'مسكنات الألم' : 'Pain killers',
        'vitamins': (language === 'arabic') ? 'الفيتامينات' : 'Vitamins',
        'colds': (language === 'arabic') ? 'سعال البرد' : 'Cold/cough',
        'body_care': (language === 'arabic') ? 'العناية بالجسم' : 'Body care',
        'hygiene': (language === 'arabic') ? 'صحة' : 'Hygiene',
        'first_aid': (language === 'arabic') ? 'إسعافات أولية' : 'First aid',
    };
    
    return (
        <div id='searchpage-container'>
            <SessionAlert/>
            <h2 id='searchresults-h2'>Results for: {searchValue}</h2>
            <section id="big-search-container">
                <aside id="listAside">
                    <h4 style={{color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300'}}>Categories</h4>
                    <ul>
                        {Object.entries(categories).map(([catKey, catName]) => (
                            <li key={catKey}>
                                <a href={`/product/category/${catKey}`}>
                                    {catName}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <hr/>
                    <div id="brand-filter">
                        <h5 style={{color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300'}}>{'Brands'}</h5>
                    </div>
                </aside>
                <aside id="productsAside">
                    <div className="search-container">
                        {searchData.map(product => (
                            <ProductItem key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={language}/>
                        ))}
                    </div>
                </aside>
            </section>
        </div>
    );
}