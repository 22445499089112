import '../css/ResetPassword.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import SimpleAlert from '../components/SimpleAlert';
import CircularIndeterminate from '../components/CircularLoad';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Stack from '@mui/material/Stack';
import { useForm } from "react-hook-form";
import Box from '@mui/material/Box';

export default function ResetPassword(){
    const { userID, token } = useParams();
    const [verify, setVerify] = useState(null);
    const [message, setMessage] = useState('');
    const [type, setType] = useState();
    const [alertDisplay, setAlertDisplay] = useState('none');
    const [loaderDisplay, setLoaderDisplay] = useState(false)

    useEffect(() => {
        verifyToken();
    }, []);
  
    const verifyToken = async () => {
        try {
            const response = await fetch(`https://pharmacy.hadikaraki.net/api/user/resetpassword/${token}`);
            const data = await response.json();
            if (data.success) {
                setVerify(true)
            } else {
                setVerify(false)
            }
        } catch (error) {
            setVerify(false)
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const resetPassOption = {
        password: {
            required: "Password is empty",
            pattern: {
                value: /^[a-zA-Z0-9]{8,30}$/,
                message: "Password must be 8-30 characters long and alphanumeric"
            }
        }
    };

    const handleResetPassword = async (formData) => {
        setAlertDisplay('none')
        try {
            setLoaderDisplay(true)
            const response = await fetch(`https://pharmacy.hadikaraki.net/api/user/resetpassword/${userID}/${token}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            if (data.success) {
                setMessage('Password has been changed. You can now login');
                setType('success');
                setAlertDisplay('block');
            }
            else if (data.message === 'Wrong or expired link'){
                setMessage('Password already changed.');
                setType('error');
                setAlertDisplay('block');
            }
        } catch (error) {
            setMessage('Error. Try again');
            setType('error');
            setAlertDisplay('block');
        }
        setLoaderDisplay(false)
    };

    function Copyright(props) {
        return (
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="/">
               Pharmacy
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
    }

    if (verify === null) {
        return (
            <section id='resetpass-container'>
                <div id='resetpass-box'>
                    <CircularIndeterminate key="loading-spinner" display={true}/>
                </div>
                <Copyright key="copyright" sx={{ mt: 5 }} />
                <Link color="inherit" href="/">
                    <Button variant="outlined" style={{marginTop: '20px'}}>Home</Button>
                </Link>
            </section>
        )
    }

    return (
        <section id='resetpass-container'>
            <div id='resetpass-box'>
                <SimpleAlert message={message} type={type} display={alertDisplay}/>
                <h3 style={{marginBottom: '20px', marginTop: '10px'}}>{verify ? 'Enter new password' : 'Wrong or expired link'}</h3>
                {verify && 
                <Box key="form-box" component="form" onSubmit={handleSubmit(handleResetPassword)} noValidate sx={{ mt: 1 }}>
                    <Stack>
                        <TextField
                            id="standard-password-input"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            variant="standard"
                            required
                            {...register("password", resetPassOption.password)}
                        />
                        <small className="text-danger" style={{textAlign: 'left', marginTop: '5px'}}>
                            {errors?.password && errors.password.message}
                        </small>
                        <Button variant="outlined" type='submit' style={{borderColor: 'green', color: 'green', marginTop: '25px'}}>Submit</Button>
                    </Stack>
                </Box>}
                <div id='resetpass-loader' style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
                    <CircularIndeterminate key="loading-spinner" display={loaderDisplay}/>
                </div>
                {verify === false ? <HighlightOffIcon style={{ color: 'red', fontSize: '40px' }} /> : <></>}
            </div>
            <Copyright key="copyright" sx={{ mt: 5 }} />
            <Link color="inherit" href="/">
                <Button variant="outlined" style={{marginTop: '20px'}}>Home</Button>
            </Link>
        </section>
    )
}