import '../css/Account.css'
import { useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import Cookies from 'js-cookie';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SimpleAlert from '../components/SimpleAlert';
import { useForm } from "react-hook-form";
import useCsrfToken from '../customHooks/useCsrfToken';
import useAuthStatus from '../customHooks/useAuthStatus';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="/">
          Pharmacy
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

const defaultTheme = createTheme();

export default function Account(){
    const [language, setLanguage] = useState('english');
    const [loader, setLoader] = useState(false);
    const { user: results } = useAuthStatus();
    const csrfToken = useCsrfToken();
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
    
    const accountOptions = {
        full_name: {
            required: "Full name is required",
            minLength: {
                value: 3,
                message: "Full name must be at least 3 characters"
            },
            maxLength: {
                value: 30,
                message: "Full name cannot exceed 30 characters"
            },
            pattern: {
                value: /^[a-zA-Z0-9\s]+$/,
                message: "Full name should only contain letters"
            }
        },
        username: {
            required: "Username is required",
            minLength: {
                value: 3,
                message: "Username must be at least 3 characters"
            },
            maxLength: {
                value: 30,
                message: "Username cannot exceed 30 characters"
            },
            pattern: {
                value: /^[a-zA-Z0-9]+$/,
                message: "Username must be alphanumeric"
            }
        },
        email: {
            required: "Email is required",
            pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net)$/,
                message: "Email must be a valid .com or .net address"
            }
        }
    };

    useEffect(() => {
        if (Cookies.get('language')) {
          setLanguage(Cookies.get('language'));
        } else {
          Cookies.set('language', 'english');
          setLanguage('english');
        }
    }, []);

    const handleAccountSubmit = async (formData) => {
        setLoader(true);
        const { full_name, username, email } = formData;
        try {
            const response = await fetch("https://pharmacy.hadikaraki.net/api/user/editAccount", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'CSRF-Token': csrfToken
                },
                body: JSON.stringify({
                    user: { full_name, username, email }
                })
            });
            const data = await response.json();
            if (data.success) {
                setAlertMsg(data.message);
                setAlertType('success');
            } else {
                setAlertMsg('Error updating account');
                setAlertType('error');
            }
        } catch (error) {
            setAlertMsg('Error updating account');
            setAlertType('error');
        }
        setLoader(false);
    };

    const options = ['Mr.', 'Mrs.']

    if(!results) {
       return (
            <div id='account-bigcontainer' style={{marginTop: '5%'}}>
                <ThemeProvider key="theme-provider" theme={defaultTheme}>
                    <Container key="main-container" component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '10px'}}>
                    <Box
                        key="box"
                        sx={{
                            marginTop: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                            <Avatar key="avatar" sx={{ m: 1, bgcolor: 'green' }}>
                                <AccountCircleIcon />
                            </Avatar>
                            <Typography key="sign-up-title" component="h2" variant="h5">
                                Account
                            </Typography>
                            <CircularIndeterminate display={true}/>
                        </Box>
                    </Container>
                </ThemeProvider>
            </div>
        )
    }

    return (
        <div id='account-bigcontainer' style={{marginTop: '5%'}}>
            <SimpleAlert message={alertMsg} type={alertType} display={alertMsg !== '' ? 'block' : 'none'}/>
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <Container className='account-container' key="main-container" component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '10px'}}>
                    <CssBaseline />
                    <Box
                        key="box"
                        sx={{
                            marginTop: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: 'green' }}>
                            <AccountCircleIcon />
                        </Avatar>
                        <Typography key="sign-up-title" component="h2" variant="h5">
                            Account
                        </Typography>
                        <Box key="form-box" component="form" onSubmit={handleSubmit(handleAccountSubmit)} sx={{ mt: 3 }}>
                            <Grid key="grid-container" container spacing={2}>
                                <Grid key="full-name-field" item xs={12}>
                                    <TextField
                                        key="full-name-input"
                                        autoComplete="full-name"
                                        name="full_name"
                                        required
                                        fullWidth
                                        id="full_name"
                                        label="Full Name"
                                        autoFocus
                                        defaultValue={results.full_name}
                                        {...register("full_name", accountOptions.full_name)}
                                    />
                                    <small className="text-danger">
                                        {errors?.full_name && errors.full_name.message}
                                    </small>
                                </Grid>
                                <Grid key="username-field" item xs={12}>
                                    <TextField
                                        key="username-input"
                                        required
                                        fullWidth
                                        id="username"
                                        label="Username"
                                        name="username"
                                        autoComplete="username"
                                        defaultValue={results.username}
                                        {...register("username", accountOptions.username)}
                                    />
                                    <small className="text-danger">
                                        {errors?.username && errors.username.message}
                                    </small>
                                </Grid>
                                <Grid key="email-field" item xs={12}>
                                    <TextField
                                        key="email-input"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        defaultValue={results.email}
                                        {...register("email", accountOptions.email)}
                                    />
                                    <small className="text-danger">
                                        {errors?.email && errors.email.message}
                                    </small>
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: 'green' }}
                            >
                                Save
                            </Button>
                        </Box>
                        <div style={{marginTop: '30px'}}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                        </div>
                    </Box>
                </Container>
                <Copyright key="Pharmacy" sx={{ mt: 5 }} />
            </ThemeProvider>
        </div>
    )
}