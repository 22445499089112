import '../css/ProductItem.css'
import { addToCart } from "../utils/addToCart";
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";
import { getCartLength } from "../utils/getCartLength";
import useCsrfToken from '../customHooks/useCsrfToken';
import { useCart } from '../CartContext';

export default function ProductItem({ product, nb_in_stock, language }) {
    const [addedToCart, setAddedToCart] = useState(false)
    const csrfToken = useCsrfToken();
    const { setCartLength } = useCart();

    const handleAddToCart = async (productID, productName, productPrice, productCategory) => {
        await addToCart(productID, productName, productPrice, productCategory, 1, csrfToken, async () => {
            setAddedToCart(true);

            // Fetch the updated cart length
            const length = await getCartLength();
            setCartLength(length)
        });
    };

    const discountedPrice = product.discount > 0 ? product.price * (1 - product.discount / 100) : product.price;

    return (
        <div className="product" data-brand={product.brand}>
            <a href={`/product/${product._id}`} style={{ textDecoration: 'none' }}>
                <div className="img-box">
                    <img 
                        className="product-img" 
                        src={`${product.images[0].url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')}`}
                        loading="lazy" 
                        srcSet={`
                            ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_200,q_auto,f_auto/')} 200w, 
                            ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_400,q_auto,f_auto/')} 400w, 
                            ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')} 800w
                        `} 
                        sizes="(max-width: 600px) 200px, (max-width: 1200px) 400px, 800px" 
                        alt="Product" 
                    />
                </div>
            </a>
            <div className="item_desc">
                <a className="product_details_link" href={`/product/${product._id}`} style={{textDecoration: 'none'}}>
                    <div className="product_name_div">
                        <p style={{fontSize: '15px'}}>
                            <span className="bundle-title"></span>{product.english_name}
                        </p>
                    </div>
                </a>
                <div className="btn_price_div">
                    {product.discount > 0 ? (
                        <div>
                            <span className="original-price">${product.price.toFixed(2)}</span>
                            <span className="discounted-price">${discountedPrice.toFixed(2)}</span>
                        </div>
                    ) : (
                        <span style={{fontWeight: 'bold'}}>${product.price.toFixed(2)}</span>
                    )}
                    <div className='addcart_btn_div'>
                        <button type="button" className="add_cart_btn" onClick={() => handleAddToCart(product._id, product.english_name, product.price, product.category)} style={{display: nb_in_stock > 0 ? 'block' : 'none', backgroundColor: addedToCart ? 'grey' : 'rgb(66, 205, 66)'}}>
                            <CheckIcon style={{display: addedToCart ? 'inline' : 'none'}}/> 
                            {addedToCart ? (language === 'english' ? 'Added to cart' : 'تمت إضافتها') : (language === 'english' ? 'Add to cart' : 'أضف إلى السلة')}
                        </button>
                        <button type="button" className="add_cart_btn" style={{display: nb_in_stock === 0 ? 'block' : 'none', backgroundColor: 'grey', border: 'grey'}}>
                            Out of stock
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}