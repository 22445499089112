import * as React from 'react';
import { useNavigate  } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import { useForm } from "react-hook-form";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
         Pharmacy
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function AddPharmacyUser() {
    const [loader, setLoader] = React.useState(false);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const registerOptions = {
        username: {required: "Username cannot be blank"},
        email: {
            required: "Email cannot be blank",
            pattern: {
                value: /^([_\-\.0-9a-zA-Z]+)@([_\-\.0-9a-zA-Z]+)\.([a-zA-Z]){2,7}$/,
                message: "Invalid email address"
            }
        },
        password: {
          required: "Password is required",
          minLength: {
            value: 8,
            message: "Password must be at least 8 characters",
          },
        }
    };

    const handleAddPharmacyUser = async (formData) => {
        setLoader(true)
        const { username, email, password } = formData;
        const type = 'pharmacy_owner';
        try {
            const response = await fetch("https://pharmacy.hadikaraki.net/api/user/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: { username, email, type },
                    password
                })
            });
            if (response.ok) {
                sessionStorage.setItem('sessionMessage', 'Welcome!');
                navigate("/");
                window.location.reload();
            } else {
                console.error("Failed");
            }
            setLoader(false)
        } catch (error) {
            console.error("Error:", error);
            setLoader(false)
        }
    };
    const handleError = (errors) => {};

    return (
        <div id='signup-container' style={{marginTop: '5%'}}>
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <Container key="main-container" component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '20px'}}>
                    <CssBaseline />
                    <Box
                        key="box"
                        sx={{
                            marginTop: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: 'green' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography key="sign-up-title" component="h1" variant="h5">
                            Pharmacy owner
                        </Typography>
                        <Box key="form-box" component="form" noValidate onSubmit={handleSubmit(handleAddPharmacyUser, handleError)} sx={{ mt: 3 }}>
                            <Grid key="grid-container" container spacing={2}>
                                <Grid key="username-field" item xs={12}>
                                    <TextField
                                        key="username-input"
                                        required
                                        fullWidth
                                        id="username"
                                        label="Username"
                                        name="username"
                                        autoComplete="username"
                                        {...register("username", registerOptions.username)}
                                    />
                                    <small className="text-danger">
                                        {errors?.username && errors.username.message}
                                    </small>
                                </Grid>
                                <Grid key="email-field" item xs={12}>
                                    <TextField
                                        key="email-input"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        {...register("email", registerOptions.email)}
                                    />
                                    <small className="text-danger">
                                        {errors?.email && errors.email.message}
                                    </small>
                                </Grid>
                                <Grid key="password-field" item xs={12}>
                                    <TextField
                                        key="password-input"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        {...register("password", registerOptions.password)}
                                    />
                                    <small className="text-danger">
                                        {errors?.password && errors.password.message}
                                    </small>
                                </Grid>
                            </Grid>
                            <Button
                                key="sign-up-button"
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: 'green' }}
                            >
                                Save
                            </Button>
                        </Box>
                        <div style={{marginTop: '30px'}}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                        </div>
                    </Box>
                </Container>
                <Copyright key="copyright" sx={{ mt: 5 }} />
            </ThemeProvider>
        </div>
    );
}