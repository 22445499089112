import '../css/Home.css'
import { useEffect, useState, useRef } from "react";
import Category from "../components/Category"
import panadol from '../assets/panadol-extra.avif';
import vitamins from '../assets/centrum.jpg';
import colds from '../assets/cold-cough.jpg';
import body_care from '../assets/dove.avif';
import hygiene from '../assets/head_shoulders.jpg';
import first_aid from '../assets/first_aid.jpg';
import advil from '../assets/Advil_logo.png';
import centrum_logo from '../assets/centrum-logo.png';
import panadol_logo from '../assets/panadol-logo.png';
import beesline_logo from '../assets/beesline_logo.png';
import ProductItemCar from '../components/ProductItemCar';
import CircularIndeterminate from '../components/CircularLoad';
import Cookies from 'js-cookie';
import SimpleAlert from '../components/SimpleAlert';
import BrandComponent from '../components/BrandComponent';
import SessionAlert from '../components/SessionAlert';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DiscountIcon from '@mui/icons-material/Discount';
// import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Home({message, type, alertDisplay}){
    const [data, setData] = useState([]);
    const [discountProducts, setDiscountProducts] = useState([]);
    const [loader, setLoader ] = useState(true)
    const [language, setLanguage] = useState('english');
    
    const translations = {
        english: {
            category1: 'Pain killers',
            category2: 'Vitamins',
            category3: 'Cold/cough',
            category4: 'Body care',
            category5: 'Hygiene',
            category6: 'First aid',
        },
        arabic: {
            category1: 'مسكنات الألم',
            category2: 'الفيتامينات',
            category3: 'سعال البرد',
            category4: 'العناية بالجسم',
            category5: 'صحة',
            category6: 'إسعافات أولية',
        },
    };

    useEffect(() => {
        if (Cookies.get('language')) {
            setLanguage(Cookies.get('language'));
        } else {
            Cookies.set('language', 'english');
            setLanguage('english');
        }
        fetchAllProducts();
        fetchDiscountedProducts();
    }, []);
    
    const fetchAllProducts = async () => {
        try {
          const response = await fetch('https://pharmacy.hadikaraki.net/api/product/all');
          if (response.ok) {
            const products = await response.json();
            setLoader(false)
            setData(products);
          } else {
            console.error('Failed to fetch products');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const fetchDiscountedProducts = async () => {
        try {
          const response = await fetch('https://pharmacy.hadikaraki.net/api/product/discounted-products-home');
          if (response.ok) {
            const productsWithDiscount = await response.json();
            // setLoader(false)
            setDiscountProducts(productsWithDiscount);
          } else {
            console.error('Failed to fetch discount products');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const listRef = useRef(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const itemWidth = 150;
    const padding = 10;

    const scrollLeftHandler = () => {
        listRef.current.scrollLeft -= itemWidth + padding;
        setScrollLeft(listRef.current.scrollLeft);
    };

    const scrollRightHandler = () => {
        listRef.current.scrollLeft += itemWidth + padding;
        setScrollLeft(listRef.current.scrollLeft);
    };
      
    return (
        <div dir={language === 'arabic' ? 'rtl' : 'ltr'} lang={language === 'arabic' ? 'ar' : 'en'}>
            <SimpleAlert key="alert" message={message} type={type} display={alertDisplay}/>
            <SessionAlert/>

            {/* <Carousel >
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src='https://res.cloudinary.com/dvxvgwx9m/image/upload/v1716628424/Pharmacy/ysgz7azrzxqqv9hnsatj.jpg'
                        alt="First slide"
                        height='300px'
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src='https://res.cloudinary.com/dvxvgwx9m/image/upload/v1716628424/Pharmacy/ysgz7azrzxqqv9hnsatj.jpg'
                        alt="Second slide"
                        height='300px'
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src='https://res.cloudinary.com/dvxvgwx9m/image/upload/v1716628424/Pharmacy/ysgz7azrzxqqv9hnsatj.jpg'
                        alt="Third slide"
                        height='300px'
                    />
                </Carousel.Item>
            </Carousel> */}
            
            <section id='carousel-bigcontainer'>
                <h2 key="products-title" id='productshome-h2' style={{}}>{language === 'arabic' ? 'منتجات' : 'Products'}</h2>
                <div key="carousel-container" className="container-car">
                    <div dir='ltr' lang='en' key="carousel-view" className="carousel-view">
                        <button key="prev-btn" id="prev-btn" className="prev-btn" onClick={scrollLeftHandler}>
                            {/* <svg viewBox="0 0 512 512" width="20" title="chevron-circle-left">
                                <path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z" />
                            </svg> */}
                            <ArrowLeftIcon style={{ fontSize: '25px' }}/>
                        </button>
                        <div key="item-list" id="item-list" className="item-list" ref={listRef} style={{ scrollLeft: scrollLeft }}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                            {data.map(product => (
                                <ProductItemCar key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={language}/>
                            ))}
                        </div>
                        <button key="next-btn" id="next-btn" className="next-btn" onClick={scrollRightHandler}>
                            {/* <svg viewBox="0 0 512 512" width="20" title="chevron-circle-right">
                                <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z" />
                            </svg> */}
                            <ArrowRightIcon style={{ fontSize: '25px' }}/>
                        </button>
                    </div>
                </div>
            </section>

            <section key="categories-container" id="categories-container">
                <h2 key="categories-title" id='categorieshome-h2' style={{}}>{language === 'arabic' ? 'فئات': 'Categories'}</h2>
                <div id='categories-boxes'>
                    <Category key="pain-killers" imgSource={panadol} category={"pain_killers"} label={translations[language].category1}/>
                    <Category key="vitamins" imgSource={vitamins} category={"vitamins"} label={translations[language].category2}/>
                    <Category key="cold-cough" imgSource={colds} category={"colds"} label={translations[language].category3}/>
                    <Category key="body-care" imgSource={body_care} category={"body_care"} label={translations[language].category4}/>
                    <Category key="hygiene" imgSource={hygiene} category={"hygiene"} label={translations[language].category5}/>
                    <Category key="first-aid" imgSource={first_aid} category={"first_aid"} label={translations[language].category6}/>
                </div>
            </section>

            {discountProducts.length !== 0 ? 
            <section id='carousel-bigcontainer'>
                <div className="header-container">
                    <h2 key="discountproducts-title" id='discountproductshome-h2' style={{ fontWeight: '500' }}>
                        {language === 'arabic' ? 'منتجات' : 'Discounts'} <DiscountIcon style={{ color: 'green' }} />
                    </h2>
                    <a href='/product/discounts' style={{textDecoration: 'none'}}>
                        <button>Show all</button>
                    </a>
                </div>
                <div key="carousel-container" className="container-car">
                    <div dir='ltr' lang='en' key="carousel-view" className="carousel-view">
                        <button key="prev-btn" id="prev-btn" className="prev-btn" onClick={scrollLeftHandler}>
                            <ArrowLeftIcon style={{ fontSize: '25px' }} />
                        </button>
                        <div key="item-list" id="item-list" className="item-list" style={{ scrollLeft: scrollLeft }}>
                            <CircularIndeterminate key="loading-spinner" display={loader} />
                            {discountProducts.map(product => (
                                <ProductItemCar key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={language} />
                            ))}
                        </div>
                        <button key="next-btn" id="next-btn" className="next-btn" onClick={scrollRightHandler}>
                            <ArrowRightIcon style={{ fontSize: '25px' }} />
                        </button>
                    </div>
                </div>
            </section>
            : <></>}
            
            <section key="brands-container" id="brands-container">
                <h2 key="brands-title" id='brandshome-h2' style={{}}>{language === 'arabic' ? 'فئات': 'Brands'}</h2>
                <div id='brands-boxes'>
                    <BrandComponent imgSource={advil} brand="advil"/>
                    <BrandComponent imgSource={centrum_logo} brand="centrum"/>
                    <BrandComponent imgSource={panadol_logo} brand="panadol"/>
                    <BrandComponent imgSource={ beesline_logo} brand="beesline"/>
                </div>
            </section>
        </div>
    )
}