import * as React from 'react';
import { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularIndeterminate from '../components/CircularLoad';
import SimpleAlert from '../components/SimpleAlert';

export default function AllUsers(){
    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [userIdToRemove, setUserIdToRemove] = useState(null);
    const [usernameToRemove, setUsernameToRemove] = useState('');
    const [alertDisplay, setAlertDisplay] = useState('none');

    const handleClickOpen = (userId, username) => {
        setOpen(true);
        setUserIdToRemove(userId);
        setUsernameToRemove(username);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function removeUser(userId) {
        const userElement = document.getElementById(`user-${userId}`);
        if (userElement) {
            userElement.remove();
        }
    }

    const handleRemove = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const typedUsername = formJson.username;

        if (typedUsername === usernameToRemove) {
            // Typed username matches the username of the row
            fetch(`https://pharmacy.hadikaraki.net/api/user/remove/${userIdToRemove}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Handle success (e.g., update UI)
            })
            .catch(error => {
                console.error('Error removing user:', error);
            });
            handleClose();
            removeUser(userIdToRemove);
            setAlertDisplay('block');
        } else {
            alert('Entered username does not match.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://pharmacy.hadikaraki.net/api/user/all');
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    if(data.length === 0) {
        return (
            <div id="table">
                <Table bordered>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Full name</th>
                            <th>Username</th>
                            <th style={{color: 'red'}}>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <CircularIndeterminate display={true}/>
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <div>
            <SimpleAlert message="Removed user" type='success' display={alertDisplay}/>

            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: handleRemove
                    }}
                >
                    <DialogTitle>Remove user</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        To remove the user, please enter the full username to make sure.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="username"
                        name="username"
                        label="Username"
                        type="text"
                        fullWidth
                        variant="standard"
                        placeholder={usernameToRemove}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Remove</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        
            <div id="table">
                <Table bordered>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Full name</th>
                            <th>Username</th>
                            <th style={{color: 'red'}}>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((user) => (
                            <tr id={`user-${user._id}`}>
                                <td>{user._id}</td>
                                <td>
                                    {user.first_name} {user.last_name} 
                                </td>
                                <td>
                                    {user.username}
                                </td>
                                <td>
                                    <Button style={{backgroundColor: 'red'}} onClick={() => handleClickOpen(user._id, user.username)} variant="contained">Remove</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

        </div>
    );
}