export const getCartLength = async() => {
    try {
        const response = await fetch('http://backend-service:5006/user/cart');
        if (response.ok) {
            const results = await response.json();
            return results.cart.length;
        } else {
            console.error('Failed to fetch updated cart length');
        }
    } catch (error) {
        console.error('Error fetching updated cart length:', error);
    }
}