import '../css/ProductItemCar.css'
import { addToCart } from "../utils/addToCart";
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";
import { getCartLength } from "../utils/getCartLength";
import useCsrfToken from '../customHooks/useCsrfToken';
import { useCart } from '../CartContext';

export default function ProductItemCar({ product, nb_in_stock, language }) {
    const [addedToCart, setAddedToCart] = useState(false)
    const csrfToken = useCsrfToken();
    const { setCartLength } = useCart();

    const handleAddToCart = async(productID, productName, productPrice, productCategory) => {
        await addToCart(productID, productName, productPrice, productCategory, 1, csrfToken, async() => {
            setAddedToCart(true)

            // Fetch the updated cart length
            const length = await getCartLength();
            setCartLength(length)
        });
    }

    const discountedPrice = product.discount > 0 ? product.price * (1 - product.discount / 100) : product.price;

    return (
        <div className="item-div">
            <a href={`/product/${product.id}`} style={{ textDecoration: 'none' }}>
                <img 
                    id="item" 
                    className="item" 
                    src={`${product.images[0].url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')}`}
                    loading="lazy" 
                    srcSet={`
                        ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_200,q_auto,f_auto/')} 200w, 
                        ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_400,q_auto,f_auto/')} 400w, 
                        ${product.images[0].url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')} 800w
                    `} 
                    sizes="(max-width: 600px) 200px, (max-width: 1200px) 400px, 800px" 
                    alt="Product" 
                />
                <p 
                    dir={language === 'arabic' ? 'rtl' : 'ltr'} 
                    lang={language === 'arabic' ? 'ar' : 'en'} 
                    style={{ color: 'black', fontWeight: 100 }} 
                    className="item-name"
                >
                    {language === 'english' ? product.english_name : product.arabic_name}
                </p>
                {product.discount > 0 ? (
                    <div className="price-container" style={{margin: '10px 0'}}>
                        <span className="original-price">${product.price.toFixed(2)}</span>
                        <span className="discounted-price">${discountedPrice.toFixed(2)}</span>
                    </div>
                ) : (
                    <div style={{margin: '10px 0'}}>
                        <span 
                            dir={language === 'arabic' ? 'rtl' : 'ltr'} 
                            lang={language === 'arabic' ? 'ar' : 'en'} 
                            style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}
                        >
                            ${product.price.toFixed(2)}
                        </span>
                    </div>
                )}
            </a>
            <button 
                id="cart-btn" 
                type="button" 
                onClick={() => handleAddToCart(product.id, product.english_name, product.price, product.category)} 
                style={{ display: nb_in_stock > 0 ? 'block' : 'none', backgroundColor: addedToCart ? 'grey' : 'rgb(66, 205, 66)' }}
            >
                <CheckIcon style={{ display: addedToCart ? 'inline' : 'none' }}/> 
                {addedToCart ? (language === 'english' ? 'Added to cart' : 'تمت إضافتها') : (language === 'english' ? 'Add to cart' : 'أضف إلى السلة')}
            </button>
            <button 
                type="button" 
                id="cart-btn" 
                style={{ display: nb_in_stock === 0 ? 'block' : 'none', backgroundColor: 'grey' }}
            >
                Out of stock
            </button>
        </div>
    );
}