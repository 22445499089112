import '../css/Navbar.css'
import * as React from 'react';
import { useEffect } from "react";
import { logout } from "../utils/logout";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Badge from '@mui/material/Badge';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuItem from '@mui/material/MenuItem';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Link } from 'react-router-dom';
import locationIcon from '../assets/location.jpg';
import pharmacyMobile from '../assets/pharmacy_mobile.PNG';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import useAuthStatus from '../customHooks/useAuthStatus';
import { useCart } from '../CartContext';
import { useWishlist } from '../WishlistContext';

export default function Navbar() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [language, setLanguage] = React.useState('english');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const { user, authenticated } = useAuthStatus();
  const { cartLength, setCartLength } = useCart();
  const { wishlistLength, setWishlistLength } = useWishlist();
  const navigate = useNavigate();

  const pages = [(authenticated && user.admin) ? 'Admin' : ''];
  const arabicToEnglish = {
      'الحساب': 'account',
      'العناوين': 'addresses',
      'الطلبات المسبقة': 'order-history',
      'تسجيل الدخول': 'login',
      'اشتراك': 'signup'
  };
  const arabicPages = ['الحساب', 'العناوين', 'الطلبات المسبقة'];
  const englishPages = [ 'Account', 'Addresses', 'Order history'];
  const englishLoginPages = ['Login', 'Signup'];
  const arabicLoginPages = ['تسجيل الدخول', 'اشتراك'];
  const settings = authenticated ? (language === 'english' ? englishPages : arabicPages) : (language === 'english' ? englishLoginPages : arabicLoginPages);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmitSearch();
      setSearchTerm('');
      setSearchResults([]);
    }
  };

  const handleLogout = () => {
    logout();
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSearchChange = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.length === 0) {
      setSearchResults([]);
      return;
    }
    if (value.length >= 2) {
      try {
        const response = await fetch(`https://pharmacy.hadikaraki.net/api/autocomplete?name=${value}`);
        if (response.ok) {
          const data = await response.json();
          setSearchResults(data);
        } else {
          console.error('Failed to fetch autocomplete results');
        }
      } catch (error) {
        console.error('Error fetching autocomplete results:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSubmitSearch = async () => {
      const value = searchTerm; // Directly use the state value
      if(value.length === 0) {
        return;
      }
      try {
        const response = await fetch(`https://pharmacy.hadikaraki.net/api/search?name=${value}`);
        if (response.ok) {
          const data = await response.json();
          // send data as prop and redirect to search results page
          setSearchResults([]);
          navigate('/search-results', { state: { searchData: data, value } });
        } else {
          console.error('Failed to fetch results');
        }
      } catch (error) {
        console.error('Error fetching results:', error);
      }
  };

  function handleAddressChange(event) {
    var selectedAddress = event.target.value;
    var address = { address: selectedAddress };

    fetch('https://pharmacy.hadikaraki.net/api/set-address', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(address)
    })
    .then(function(response) {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    })
    .catch(function(error) {
        console.error('Error changing address:', error);
    });
  }

  useEffect(() => {
      if (Cookies.get('language')) {
        setLanguage(Cookies.get('language'));
      } else {
        Cookies.set('language', 'english');
        setLanguage('english');
      }
      fetchCartLength();
      if(user) {
        setWishlistLength(user.wishlist.length)
      }
  }, [user]);

  const fetchCartLength = async () => {
      if (!user) {
          try {
              const response = await fetch('https://pharmacy.hadikaraki.net/api/user/cart');
              if (response.ok) {
                  const results = await response.json();
                  setCartLength(results.cart.length);
              } else {
                  console.error('Failed to fetch cart');
              }
          } catch (error) {
              console.error('Error:', error);
          }
      } else {
          setCartLength(user.cart.length);
      }
  };

  const changeLanguage = () => {
      Cookies.set('language', language === 'english' ? 'arabic' : 'english');
      setLanguage(language === 'english' ? 'arabic' : 'english');
      window.location.reload();
  }

  return (
    <nav key="nav">
      <Box key="nav-box" sx={{ flexGrow: 1 }}>
        <AppBar key="app-bar" position="static" sx={{ backgroundColor: "green", marginBottom: '30px', boxShadow: 'none' }}>
          <Toolbar id="nav-toolbar" style={{ width: '90%', margin: '0 auto' }}>
            <LocalHospitalIcon key="local-hospital-icon1" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            {/* LEFT SIDE */}
            <Typography
              key="typography1"
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              PHARMACY
            </Typography>
  
            <Box key="menu-icon-box" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                key="menu-icon"
                size="large"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Typography
              key="typography2"
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                // letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                justifyContent: 'center'
              }}
            >
              {/* <LocalHospitalIcon key="local-hospital-icon2" sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
              pharmacy
            </Typography>
            <Box key="pages-box" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {/* {pages.map((page) => (
                <Button
                  key={page}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              ))} */}
              <Button key="language-button" onClick={changeLanguage} sx={{ fontSize: 'inherit', textTransform: 'none', color: 'white', display: 'block' }}>
                {language === 'english' ? 'العربية' : 'English'}
              </Button>
              <Link key="admin-link" to="/user/admin" style={{ textDecoration: 'none', display: (authenticated && user.admin) ? 'block' : 'none' }}>
                <Button key="admin-button" sx={{ fontSize: 'inherit', textTransform: 'none', color: 'white', display: 'block' }}>Admin</Button>
              </Link>
            </Box>
  
            {/* SEARCH BAR */}
            <div key='search-bar-box' id='search-bar-box'>
              <input
                key="search-input"
                className="form-control mr-sm-2"
                id='search-bar'
                type="search"
                dir={language === 'arabic' ? 'rtl' : 'ltr'}
                placeholder={language === 'arabic' ? 'ابحث عما تريد' : 'Search entire store'}
                aria-label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                required
              />
              <button key="search-button" className="btn btn-outline-success" id="search-btn" type="button" onClick={handleSubmitSearch}>
                <i key="search-icon" className="fa fa-search"></i>
              </button>
              {searchResults.length > 0 && (
                <ul key='search-list' id='ulsearch-list'>
                  {searchResults.map((result) => (
                    <a key={result._id} href={`/product/${result._id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                      <li style={{ color: 'black', fontWeight: '500', backgroundColor: '#fafaf2', padding: '5px' }}>
                        <img alt='product' src={result.images[0].url} height='50px' width='50px' style={{ marginRight: '10px' }} /> {result.english_name}
                      </li>
                    </a>
                  ))}
                </ul>
              )}
            </div>

            {/* RIGHT SIDE */}
            <Box key="right-side-box" sx={{ flexGrow: 1 }} />
            {/* ICONS  */}
            {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}> */}
            {/* WISHLIST */}
            <Link key="wishlist-link" to="/user/wishlist" style={{ color: 'inherit', textDecoration: 'none' }}>
              <IconButton key="fav-icon" id="fav-icon" size="large" aria-label="show 4 wishlist items" color="inherit">
                <Badge key="fav-badge" badgeContent={authenticated ? wishlistLength : 0} color="error">
                  <FavoriteIcon key="fav-icon-inner" />
                </Badge>
              </IconButton>
            </Link>
  
            {/* SHOPPING CART */}
            <Link key="cart-link" to="/user/cart" style={{ color: 'inherit', textDecoration: 'none' }}>
              <IconButton key="cart-icon" id="cart-icon" size="large" aria-label="show 4 items" color="inherit">
                <Badge key="cart-badge" badgeContent={cartLength} color="error">
                  <ShoppingCartIcon key="cart-icon-inner" />
                </Badge>
              </IconButton>
            </Link>
  
            <Tooltip key="tooltip" title="Open settings">
              <IconButton key="icon-button" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar key="avatar2" sx={{ bgcolor: "green" }}>
                  <AccountCircleIcon key="account-circle-icon" />
                </Avatar>
              </IconButton>
            </Tooltip>
            {/* </Box> */}
  
            {/* THE LINKS FOR THE ACCOUNT ICON */}
            <Menu
              key="menu"
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <Link key={`setting-${setting}`} to={language === 'english' ? (`/user/${setting.toLowerCase().replace(/\s+/g, '-')}`) : (`/user/${arabicToEnglish[setting]}`)} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <MenuItem key={`menu-item-${setting}`} onClick={handleCloseUserMenu} style={{ width: '180px' }}>
                    <Typography
                      key={`menu-item-typography-${setting}`}
                      textAlign="center"
                      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                    >
                      <span>{setting}</span>
                      <span>
                        {setting === 'Account' && <AccountCircleIcon key={`account-icon-${setting}`} style={{ color: 'green' }} />}
                        {setting === 'Addresses' && <HomeIcon key={`home-icon-${setting}`} style={{ color: 'green' }} />}
                        {setting === 'Order history' && <ReceiptLongIcon key={`receipt-icon-${setting}`} style={{ color: 'green' }} />}
                        {setting === 'Login' && <LoginIcon key={`login-icon-${setting}`} style={{ color: 'green' }} />}
                        {setting === 'Signup' && <MailOutlineIcon key={`mail-icon-${setting}`} style={{ color: 'green' }} />}
                      </span>
                    </Typography>
                  </MenuItem>
                  <hr key={`menu-item-divider-${setting}`} style={{ margin: '0px', color: 'grey', display: setting === 'Signup' ? 'none' : 'block' }}></hr>
                </Link>
              ))}
              {authenticated ? (
                <div>
                  <Link key='user-card' to='/user/card' style={{ color: 'inherit', textDecoration: 'none' }}>
                    <MenuItem key="credit-card-menu-item" onClick={handleCloseUserMenu} style={{ width: '180px' }}>
                      <Typography
                        key="credit-card-typography"
                        textAlign="center"
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                      >
                        <span>Credit card</span>
                        <span><CreditCardIcon key="credit-card-icon" style={{ color: 'green' }} /></span>
                      </Typography>
                    </MenuItem>
                    <hr key="credit-card-divider" style={{ margin: '0px', color: 'grey' }}></hr>
                  </Link>
                  <div key="logout-div" onClick={handleLogout}>
                    <MenuItem key="logout-menu-item" onClick={handleCloseUserMenu} style={{ width: '180px' }}>
                      <Typography
                        key="logout-typography"
                        textAlign="center"
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                      >
                        <span>{language === 'english' ? 'Logout' : 'تسجيل خروج'}</span>
                        <span><LogoutIcon key="logout-icon" style={{ color: 'green' }} /></span>
                      </Typography>
                    </MenuItem>
                  </div>
                </div>
              ) : null}
            </Menu>
          </Toolbar>
        </AppBar>
        {/* DRAWER IN MOBILE SCREENS */}
        <Drawer
            key="drawer"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '240px', textAlign: 'center' },
            }}
          >
          <Typography key="drawer-typography" variant="h6" sx={{ my: 2 }}>
            <LocalHospitalIcon style={{color: 'green'}}/> Pharmacy
          </Typography>
          <Divider key="divider"/>
          {/* THE LINKS INSIDE THE DRAWER */}
            <List key="drawer-list">
              {pages.map((item) => (
                <Link key={`user-${item}`} to={`/user/${item}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <ListItem key={item} disablePadding>
                    <ListItemButton key={item} sx={{ textAlign: 'center' }}>
                      <ListItemText primary={item} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
        </Drawer>
      </Box>
      <div key="brand-cart-wish-mobile" id="brand-cart-wish-mobile" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0' }}>
            <a key="brand-link" href="/" style={{ marginLeft: '10px', marginRight: 'auto' }}>
                <img
                    key="brand-mobile"
                    id="brand-mobile"
                    style={{ display: 'block' }}
                    src={pharmacyMobile}
                    height="30"
                    alt="Brand Mobile"
                />
            </a>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link key="cart-link-mobile" to="/user/cart" style={{ display: 'inline', fontSize: 'large' }}>
                    <IconButton style={{ color: '#00a152' }} key="cart-icon" size="large" aria-label={`show ${cartLength} items`} color="inherit">
                        <Badge badgeContent={cartLength} color="error">
                            <ShoppingCartIcon style={{ fontSize: '30px' }} />
                        </Badge>
                    </IconButton>
                </Link>
                <Link key="wishlist-link-mobile" to="/user/wishlist" style={{ display: 'inline', fontSize: 'large', marginRight: '10px' }}>
                    <IconButton style={{ color: '#00a152' }} key="fav-icon" aria-label={`show ${user && user.wishlist.length} items`} color="inherit">
                        <Badge badgeContent={user ? user.wishlist.length : 0} color="error">
                            <FavoriteIcon style={{ fontSize: '30px' }} />
                        </Badge>
                    </IconButton>
                </Link>
            </div>
        </div>
        <div key="search-box-mobile" id="search-box-mobile" style={{display: 'none'}}>
            <div key="search-div" className="input-group">
              <input
                key="search-input"
                className="form-control mr-sm-2"
                type="search"
                id="search-bar-mobile"
                placeholder="Search entire store"
                aria-label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                required
              />
              <button
                key="search-button"
                className="btn btn-outline-success"
                type="button"
                onClick={handleSubmitSearch}
                style={{ color: 'white', backgroundColor: 'green' }}
              >
                <i key="search-icon" className="fa fa-search"></i>
              </button>
            </div>
            <ul key="specs" id="specs" style={{listStyleType: 'none', margin: '0', padding: '0', position: 'absolute', width: '95%', zIndex: '1'}}>
              {searchResults.map((result, index) => (
                <a href={`/product/${result._id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <li key={index} style={{fontWeight: '500', backgroundColor: '#fafaf2', padding: '5px'}}>
                    <img alt='product' src={result.images[0].url} height='50px' width='50px' style={{marginRight: '10px'}}></img> {result.english_name}
                  </li>
                </a>
              ))}
            </ul>
        </div>

      <div key="location-mobile" id="location-mobile">
            <img key="location-img" alt='locationicon' src={locationIcon} width="24" height="24" />
            <p key="location-paragraph" style={{ display: 'inline', fontWeight: 100 }}>
                Delivery to
                <select
                    key="location-select"
                    className="addressSelect"
                    id="addressSelect2"
                    onChange={handleAddressChange}
                    name="addChosen"
                    style={{ color: '#20b2aa', border: '0px', marginLeft: '5px' }}
                >
                    {user && user.addresses.length === 0 ? (
                        <option key="no-address" defaultValue={-1}>
                            No addresses
                        </option>
                    ) : (
                        <> 
                          <option key="address-selected" defaultValue={user && user.favorite_address}>
                              {user && user.favorite_address >-1 && user.addresses[user.favorite_address].street}
                          </option>
                          {user && user.addresses.map((address, index) => (
                              address._id !== user.addresses[user.favorite_address]._id && <option key={address.street} value={index}>{address.street}</option>
                          ))}
                        </>
                    )}
                </select>
            </p>
        </div>
        {/* <hr key="mobile-line" id="mobile-line" style={{ display: 'none', marginBottom: '35px' }} /> */}
        <div id='mobile-line' style={{ display: 'none', marginBottom: '35px' }}></div>
    </nav>
  );
}
