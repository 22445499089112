import React, { createContext, useContext, useState } from 'react';

const WishlistContext = createContext();

export const useWishlist = () => useContext(WishlistContext);

export const WishlistProvider = ({ children }) => {
    const [wishlistLength, setWishlistLength] = useState(0);

    return (
        <WishlistContext.Provider value={{ wishlistLength, setWishlistLength }}>
            {children}
        </WishlistContext.Provider>
    );
};