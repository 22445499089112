import '../css/VerifyEmail.css'
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularIndeterminate from '../components/CircularLoad';
import useCsrfToken from '../customHooks/useCsrfToken';

export default function VerifyEmail(){
    const { email, token } = useParams();
    const [response, setResponse] = useState('');
    const csrfToken = useCsrfToken();

    useEffect(() => {
        handleVerifyEmail(email, token);
    }, []);

    const handleVerifyEmail = async (email, token) => {
        try {
            const response = await fetch(`https://pharmacy.hadikaraki.net/api/user/verify-email/${email}/${token}`, {
                method: "PUT",
                headers: {
                    'CSRF-Token': csrfToken
                }
            });
            const data = await response.json();
            setResponse(data.message)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    function Copyright(props) {
        return (
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="/">
               Pharmacy
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
    }

    return (
        <section id='verifyemail-container'>
            <div id='verifyemail-box'>
            {response === '' ? (
            <CircularIndeterminate key="loading-spinner" display={true} />
            ) : (
            <>
                <h1>{response}</h1>
                {response === 'Email verified' ? (
                <CheckCircleOutlineIcon style={{ color: 'green', fontSize: '40px' }} />
                ) : (
                <HighlightOffIcon style={{ color: 'red', fontSize: '40px' }} />
                )}
            </>
            )}
            </div>
            <Copyright key="copyright" sx={{ mt: 5 }} />
            <Link color="inherit" href="/">
                <Button variant="outlined" style={{marginTop: '20px'}}>Home</Button>
            </Link>
        </section>
    )
}