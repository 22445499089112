import { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import SimpleAlert from '../components/SimpleAlert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import SessionAlert from '../components/SessionAlert';
import { useForm } from "react-hook-form";
import useCsrfToken from '../customHooks/useCsrfToken';

const defaultTheme = createTheme();

export default function AddCard(){
    const [successCardAlert, setSuccessCardAlert] = useState('none')
    const [results, setResults] = useState(null)
    const [errorCardAlert, setErrorCardAlert] = useState('none')
    const [alertMessage, setAlertMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const csrfToken = useCsrfToken();

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
    
    const cardOptions = {
        card_name: {
            required: "Name cannot be blank",
            maxLength: {
                value: 100,
                message: "Name cannot exceed 100 characters"
            },
            pattern: {
                value: /^[a-zA-Z0-9\s]+$/,
                message: "Card name should only contain letters"
            }
        },
        card_number: {
            required: "Number is required",
            pattern: {
                value: /^\d{16}$/,
                message: "Number must be exactly 16 digits"
            }
        },
        cvv: {
            required: "CVV is required",
            pattern: {
                value: /^\d{3,4}$/,
                message: "CVV must be 3 or 4 digits"
            }
        },
        exp_date: {
            required: "Exp date cannot be blank",
            pattern: {
                value: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
                message: "Exp date must be in MM/YY or MM/YYYY format"
            }
        }
    };

    useEffect(() => {
        fetchCardInfo();
    }, []);

    const fetchCardInfo = async () => {
        try {
          const response = await fetch('https://pharmacy.hadikaraki.net/api/user/payment-form');
          if (response.ok) {
            const results = await response.json();
            setResults(results);
          } else {
            console.log('No card is saved');
            setResults('');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };      

    const handleCardSubmit = async (formData) => {
        setLoader(true);
        try {
            if (results === '') {
                const response = await fetch("https://pharmacy.hadikaraki.net/api/user/add-card", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    headers: {
                        'CSRF-Token': csrfToken
                    },
                    body: JSON.stringify(formData),
                });
                const data = await response.json();
                if (data.success) {
                    sessionStorage.setItem('sessionMessage', data.message);
                    window.location.reload();
                } else {
                    setErrorCardAlert('block')
                    setAlertMessage(data.message)
                }
            } else {
                // update existing card
                const response = await fetch("https://pharmacy.hadikaraki.net/api/user/update-card", {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    headers: {
                        'CSRF-Token': csrfToken
                    },
                    body: JSON.stringify(formData),
                });
                const data = await response.json();
                if (data.success) {
                    setSuccessCardAlert('block')
                    setAlertMessage(data.message)
                } else {
                    setErrorCardAlert('block')
                    setAlertMessage('Error updating card')
                }
            }
            setLoader(false);
        } catch (error) {
            console.error("Error:", error);
            setLoader(false);
        }
    };

    const deleteCard = async () => {
        setLoader(true);
        try {
            const response = await fetch("https://pharmacy.hadikaraki.net/api/user/delete-card", {
                method: "PUT",
                headers: {
                    'CSRF-Token': csrfToken
                }
            });
            const data = await response.json();
            if (data.success) {
                setSuccessCardAlert('block')
                setResults('')
                setAlertMessage(data.message)
            } else {
                setErrorCardAlert('block')
                setAlertMessage('Error deleting card')
            }
            setLoader(false);
        } catch (error) {
            console.error("Error:", error);
            setLoader(false);
        }
    };

    function Copyright(props) {
        return (
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="/">
              Pharmacy
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
    }

    if(results === null){
        return (
            <div id="creditcard-container" style={{marginTop: '5%'}}>
                <ThemeProvider key="theme-provider" theme={defaultTheme}>
                    <Container key="main-container" component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            key="box"
                            sx={{
                                marginTop: 5,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}
                        >
                            <Avatar key="avatar" sx={{ m: 1, bgcolor: "green" }}>
                                <CreditCardIcon />
                            </Avatar>
                            <CircularIndeterminate display={true}/>
                        </Box>
                    </Container>
                </ThemeProvider>
            </div>
        );
    }

    return (
        <div id="creditcard-container" style={{marginTop: '5%'}}>
            <SessionAlert/>
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <SimpleAlert key="message1-alert" message={alertMessage} type="success" display={successCardAlert}/>
                <SimpleAlert key="message2-alert" message={alertMessage} type="error" display={errorCardAlert}/>
                <Container key="main-container" component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '20px'}}>
                    <CssBaseline />
                    <Box
                        key="box"
                        sx={{
                            marginTop: 5,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: "green" }}>
                            <CreditCardIcon />
                        </Avatar>
                        <Typography key="sign-in-title" component="h1" variant="h5">
                            {results === '' ? 'Add card' : 'Update card'}
                        </Typography>
                        <Box key="form-box" component="form" onSubmit={handleSubmit(handleCardSubmit)} sx={{ mt: 1 }}>
                            <Grid key="grid-container" container spacing={2}>
                                <Grid key="cardname-field" item xs={12}>
                                    <TextField
                                        key="cardname-input"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name on card"
                                        name="card_name"
                                        autoComplete="name"
                                        defaultValue={results.decryptedCardName}
                                        autoFocus
                                        {...register("card_name", cardOptions.card_name)}
                                    />
                                    <small className="text-danger">
                                        {errors?.card_name && errors.card_name.message}
                                    </small>
                                </Grid>
                                <Grid key="cardnb-field" item xs={12}>
                                    <TextField
                                        key="cardnb-input"
                                        required
                                        fullWidth
                                        id="cardnb"
                                        label="Card number"
                                        name="card_number"
                                        autoComplete="cardnb"
                                        defaultValue={results.decryptedCardNumber}
                                        autoFocus
                                        {...register("card_number", cardOptions.card_number)}
                                    />
                                    <small className="text-danger">
                                        {errors?.card_number && errors.card_number.message}
                                    </small>
                                </Grid>
                                <Grid key="cvv-field" item xs={12} sm={6}>
                                    <TextField
                                        key="cvv-input"
                                        required
                                        fullWidth
                                        name="cvv"
                                        label="Cvv"
                                        type="cvv"
                                        id="cvv"
                                        defaultValue={results.decryptedCVV}
                                        autoComplete="cvv"
                                        {...register("cvv", cardOptions.cvv)}
                                    />
                                    <small className="text-danger">
                                        {errors?.cvv && errors.cvv.message}
                                    </small>
                                </Grid>
                                <Grid key="expdate-field" item xs={12} sm={6}>
                                    <TextField
                                        key="expdate-input"
                                        required
                                        fullWidth
                                        name="exp_date"
                                        label="Expiration date"
                                        type="expdate"
                                        id="expdate"
                                        defaultValue={results.decryptedExpDate}
                                        autoComplete="expdate"
                                        {...register("exp_date", cardOptions.exp_date)}
                                    />
                                    <small className="text-danger">
                                        {errors?.exp_date && errors.exp_date.message}
                                    </small>
                                </Grid>
                                <Grid key="save-field" item xs={12} sm={6}>
                                    <Button
                                        key="save-button"
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 1, mb: 1, bgcolor: "green" }}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                                <Grid key="delete-field" item xs={12} sm={6}>
                                    <Button
                                        key="delete-button"
                                        type="button"
                                        fullWidth
                                        onClick={deleteCard}
                                        variant="contained"
                                        sx={{ mt: 1, mb: 1, bgcolor: "red", display: results !== '' ? 'block' : 'none' }}
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <div style={{marginTop: '30px'}}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                        </div>
                    </Box>
                </Container>
                <Copyright key="Pharmacy" sx={{ mt: 5 }} />
            </ThemeProvider>
        </div>
    )
}