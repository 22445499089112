import '../css/ShowOrder.css'
import * as React from 'react';
import { useState, useEffect } from "react";
import Link from '@mui/material/Link';
import CircularIndeterminate from '../components/CircularLoad';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

export default function ShowOrder() {
    const { orderNb } = useParams();
    const [order, setOrder] = useState(null);

    useEffect(() => {
        fetchOrder();
    }, []);

    const fetchOrder = async () => {
        try {
            const response = await fetch(`https://pharmacy.hadikaraki.net/api/order/${orderNb}`);
            if (response.ok) {
              const results = await response.json();
              setOrder(results.order)
            } else {
              console.error('Failed to fetch order');
            }
          } catch (error) {
            console.error('Error:', error);
          }
    };

    if (order === null) {
        return (
            <div id="table">
                <Table bordered>
                    <thead>
                        <tr>
                            <th className='cart-th' style={{ borderRight: 'none' }}>Order products</th>
                        </tr>
                    </thead>
                    <CircularIndeterminate display={true}/>
                </Table>
            </div>
        )
    }

    return (
        <div id="table">
            {/* <Avatar key="avatar" sx={{ m: 1, bgcolor: "green", textAlign: 'center' }}>
                <ReceiptLongIcon />
            </Avatar> */}
            <h1 style={{textAlign: 'center', textDecoration: 'underline'}}>Order #{order.number}</h1>
            <h3 style={{textAlign: 'center', marginBottom: '20px'}}>Status: {order.status}</h3>
            <Table bordered>
                <thead>
                    <tr>
                        <th className='cart-th' style={{ borderRight: 'none' }}>Order products</th>
                        <th style={{borderRight: 'none'}}></th>
                        <th style={{borderLeft: 'none'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {order.products.map((product) => (
                        <tr id={`product-${product.product._id}`} key={`product-${product.product._id}`}>
                            <td>
                                <img alt='product' className='productImg' src={product.product.images[0].url} style={{marginRight: '10px'}}/>
                            </td>
                            <td className='productName' style={{ borderRight: 'none' }}>
                                <Link to={`/product/${product.product._id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                    {product.product.english_name}
                                </Link>
                            </td>
                            <td className='productPrice' style={{textAlign: 'center', borderLeft: 'none'}}>${product.product.price}</td>
                            <td>{product.nb_ordered}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}