import '../css/Cart.css'
import { useEffect, useState, useRef } from "react";
import { removeFromCart, clearLocalCart } from "../utils/removeFromCart";
import CircularIndeterminate from '../components/CircularLoad';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import SimpleAlert from '../components/SimpleAlert';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import useAuthStatus from '../customHooks/useAuthStatus';
import useCsrfToken from '../customHooks/useCsrfToken';
import { useCart } from '../CartContext';
import { getCartLength } from "../utils/getCartLength";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ProductItemCar from '../components/ProductItemCar';

export default function Cart() {
    const [products, setProducts] = useState(null);
    const [data, setData] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const { authenticated: isLoggedIn, loading } = useAuthStatus();
    const csrfToken = useCsrfToken();
    const [loader, setLoader ] = useState(true);
    const { setCartLength } = useCart();
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');

    function increment(productId) {
        const product = products.find(p => p.product._id === productId);
        if (product && product.quantity < 10) {
            updateCounter(productId, 1);
        } else {
            console.warn('Cannot increment more');
        }
    }

    function decrement(productId) {
        const product = products.find(p => p.product._id === productId);
        if (product && product.quantity > 1) {
            updateCounter(productId, -1);
        } else {
            console.warn('Cannot decrement more');
        }
    }    

    const updateCounter = async (productId, quantity) => {
        try {
            const response = await fetch('https://pharmacy.hadikaraki.net/api/user/cart', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken
                },
                body: JSON.stringify({ productId, quantity })
            });
            const data = await response.json();
            if (data.success) {
                fetchCartProducts();
            } else {
                // do something
            }
        } catch (error) {
            // do something
        }
    };

    function confirmRemove(productId) {
        const confirmation = window.confirm('Are you sure you want to remove this product from your cart?');
        if (confirmation) {
            removeProduct(productId);
        }
    }

    const removeProduct = async (productId) => {
        removeFromCart(productId, csrfToken, async() => {
            // remove product when response is ok
            setProducts(products.filter(item => item.product._id !== productId));

            // Fetch the updated cart length
            const length = await getCartLength();
            setCartLength(length)
        });
    }

    const handleClearCart = async () => {
        const confirmation = window.confirm('Are you sure you want to clear your cart?');
        if (confirmation) {
            clearLocalCart(csrfToken, async() => {
                // remove product when response is ok
                setProducts([]);
                
                // Fetch the updated cart length
                const length = await getCartLength();
                setCartLength(length)
            });
        }
    };

    useEffect(() => {
        if (!loading) {
            fetchCartProducts();
        }
        // fetchAllProducts();
    }, [loading]);

    const fetchAllProducts = async () => {
        try {
          const response = await fetch('https://pharmacy.hadikaraki.net/api/product/all');
          if (response.ok) {
            const products = await response.json();
            setLoader(false)
            setData(products);
          } else {
            console.error('Failed to fetch products');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const listRef = useRef(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const itemWidth = 150;
    const padding = 10;

    const scrollLeftHandler = () => {
        listRef.current.scrollLeft -= itemWidth + padding;
        setScrollLeft(listRef.current.scrollLeft);
    };

    const scrollRightHandler = () => {
        listRef.current.scrollLeft += itemWidth + padding;
        setScrollLeft(listRef.current.scrollLeft);
    };

    const fetchCartProducts = async () => {
        let cart = [];
        try {
            const response = await fetch('https://pharmacy.hadikaraki.net/api/user/cart');
            const data = await response.json();
            if (data.success) {
                cart = data.cart;
                if (isLoggedIn) {
                  setProducts(cart);
                } else {
                    try {
                      const response = await fetch("https://pharmacy.hadikaraki.net/api/product/details", {
                          method: "POST",
                          headers: {
                              "Content-Type": "application/json"
                          },
                          body: JSON.stringify({cart}),
                      });
                      const data = await response.json();
                      if (data.success) {
                          cart = data.populatedCart;
                          setProducts(cart);
                      } else {
                          console.error('Failed to fetch products');
                      }
                  } catch (error) {
                      console.error('Error:', error);
                  }
              }
              setTotalPrice(data.totalPrice);
            } else {
                // notify user of error
            }
        } catch (error) {
            // notify user of error
        }
    };

    if (!products) {
        return (
            <div id="secondary-loading">
                <CircularIndeterminate display={true}/>
            </div>
        )
    }

    if (products.length === 0) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                height: '50vh'
              }}>
                <div style={{padding: '30px', borderRadius: '50%', backgroundColor: '#f0ebeb'}}>
                    <ShoppingCartIcon style={{color: 'green', fontSize: '60px'}}/>
                </div>
                <h2 style={{marginTop: '10px'}}>Shopping cart is empty</h2>
                <a href='/'>
                    <button style={{fontWeight: 'bold', marginTop: '10px', border: '0px', borderRadius: '10px', color: 'white', backgroundColor: '#2cd451', padding: '15px 100px'}}>Start Shopping Now</button>
                </a>
            </div>
        )
    }

    return (
        <div className='bigcart-container'>
            <SimpleAlert message={alertMsg} type={alertType} display={alertMsg !== '' ? 'block' : 'none'}/>
            <div className="cart-container">
                <div className="cart-info">
                    <p>Cart</p>
                </div>
                <div className="cart-products">
                    {products.map((product) => (
                        <div id={`cartproduct-${product.product._id}`} className="cartproduct-row" key={product.product._id}>
                            <div className="cartproduct-image">
                                <a href={`/product/${product.product.id}`}>
                                    <img
                                        src={`${product.product.images[0].url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')}`}
                                        loading="lazy"
                                        srcSet={`
                                            ${product.product.images[0].url.replace('/upload/', '/upload/c_scale,w_200,q_auto,f_auto/')} 200w,
                                            ${product.product.images[0].url.replace('/upload/', '/upload/c_scale,w_400,q_auto,f_auto/')} 400w,
                                            ${product.product.images[0].url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')} 800w
                                        `}
                                        sizes="(max-width: 600px) 200px, (max-width: 1200px) 400px, 800px"
                                        alt="Product"
                                    />
                                </a>
                            </div>

                            <div className="cartproduct-name">
                                <a href={`/product/${product.product.id}`}>
                                    <span className="product-brand">{product.product.brand}</span>
                                    <h6>{product.product.english_name}</h6>
                                </a>
                            </div>

                            <div className="cartpurchase-info">
                                <IconButton style={{ color: '#00a152' }} id={`iconbtn-${product.product._id}`} onClick={() => decrement(product.product._id)}>
                                    <RemoveIcon />
                                </IconButton>
                                <input
                                    style={{ textAlign: 'center' }}
                                    className='countInput'
                                    value={product.quantity}
                                    id={`counter-${product.product._id}`}
                                    readOnly
                                />
                                <IconButton style={{ color: '#00a152' }} onClick={() => increment(product.product._id)}>
                                    <AddIcon />
                                </IconButton>
                                <span>${product.product.price}</span>
                                <IconButton style={{ color: '#00a152' }} onClick={() => confirmRemove(product.product._id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="shipment-totals">
                    <p>
                        <span className="left-text">Shipment subtotal</span>
                        <span className="right-price">${totalPrice}</span>
                    </p>
                    <p>
                        <span className="left-text">Delivery Fees</span>
                        <span className="right-price">Up to $2</span>
                    </p>
                    <p>
                        <strong>
                            <span className="left-text">Shipment Total</span>
                            <span className="right-price"> ${totalPrice + 2}</span>
                        </strong>
                    </p>
                    <div className='checkoutclear-btns'>
                        <Link id='checkoutBtnLink' to="/user/checkout" style={{ textDecoration: 'none', display: products.length > 0 ? 'block' : 'none', marginTop: '10px' }}>
                            <Button style={{ backgroundColor: 'green', textTransform: 'none' }} id='checkoutBtn' variant="contained">
                                <ShoppingCartCheckoutIcon /> Checkout
                            </Button>
                        </Link>
                        <Button id='clearcartBtn' style={{ borderColor: 'green', color: 'green', textTransform: 'none' }} onClick={handleClearCart} variant="outlined">Clear cart</Button>
                    </div>
                </div>
            </div>

            {/* <section className='mostsellingcar-container'>
                <h3 key="mostselling-title" className='mostselling-h2'>Most selling</h3>
                <div key="carousel-container" className="container-car">
                    <div dir='ltr' lang='en' key="carousel-view" className="carousel-view">
                        <button key="prev-btn" id="prev-btn" className="prev-btn" onClick={scrollLeftHandler}>
                            <ArrowLeftIcon style={{ fontSize: '25px' }}/>
                        </button>
                        <div key="item-list" id="item-list" className="item-list" ref={listRef} style={{ scrollLeft: scrollLeft }}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                            {data.map(product => (
                                <ProductItemCar key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={'english'}/>
                            ))}
                        </div>
                        <button key="next-btn" id="next-btn" className="next-btn" onClick={scrollRightHandler}>
                            <ArrowRightIcon style={{ fontSize: '25px' }}/>
                        </button>
                    </div>
                </div>
            </section> */}
        </div>
    );
}